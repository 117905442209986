import { Component, EventEmitter, Output, OnInit, ChangeDetectorRef } from "@angular/core";
import { Observable, Subscription, catchError, forkJoin, of } from "rxjs";
import { CRGridSearch } from "src/app/modules/workout-sales/interfaces/custom-reports.interface";
import { SpinnerService } from "src/app/services/spinner.service";
import { CustomReportsFiltersService } from "../../services/custom-report-filters.service";
import { CustomReportsApiService } from "../../services/api/custom-report-api.service";
import { ToyotaDealerAtGlanceReport } from "../reportConstants";
import { getMonthAndYear, getObjFromFilteredArray } from "../../utils/common-utils";
import { CustomReportsService } from "../../services/custom-report.service";
import { ToastService } from "src/app/services/toast.service";
import { DATA_FORMAT_FLAGS, HIERARCHY_TYPE, reportUrlType } from "src/app/constants/constants";
import { Router } from "@angular/router";
import * as _ from 'lodash';
import { HrcyMock } from "../../mock/mock";
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-toyota-dealer-at-glance',
  templateUrl: './toyota-dealer-at-glance.component.html',
  styleUrls: ['./toyota-dealer-at-glance.component.scss']
})
export class ToyotaDealerAtGlanceComponent implements OnInit {

  profileHeader: string = 'DEALER PROFILE';
  showDepartmentTable: boolean = false;


  public obj = Object.values;
  @Output() updateSearch: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();

  public currentTabIndex: number = 0;
  public toyotaDealerAtGlancejsonObj: any;
  public selectedFilter = [];
  public genericProfileTableDataSource;

  public selectedTabIndex = [0];
  public subscription: Subscription = new Subscription();
  public filtersSubscription: Subscription = new Subscription();
  public isProfileTable: boolean = false;
  public sources: Observable<any>[] = [];
  public tabList: string[] = [];
  public reportId: number;
  public formattedSelectedFilter;
  public previousFilter;
  isFilterSame: boolean;
  hideColums: string[];
  headerCount: number;

  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private readonly customReportsService: CustomReportsService,
    public route: Router,
    public toast: ToastService
  ) {
    this.tabList = ToyotaDealerAtGlanceReport.ToyotaDealerAtGlanceTabNames;
    const reportTypeNameFromUrl = this.route.url.split('/').pop();
    this.reportId = reportUrlType[reportTypeNameFromUrl];
    this.getToyotaDealerAtGlanceReport();
  }

  ngOnInit(): void {
    this.subscribeReportData();
    this.displayProfileSection();
  }

  public subscribeReportData() {
    this.filtersSubscription = this.filterService.getSelectedValues()?.pipe().subscribe({
      next: (filteredValues) => {
        if (filteredValues.length > 0) {
          this.spinnerService.displaySpinner();
          if (filteredValues?.length > 0) {
            this.selectedFilter = filteredValues;
            this.isFilterSame = _.isEqual(this.selectedFilter, this.previousFilter);
            if (this.isFilterSame === false) {
              this.selectedTabIndex = [];
              this.selectedTabIndex.push(this.currentTabIndex);
            }
            if (this.toyotaDealerAtGlancejsonObj?.tabList[this.currentTabIndex]?.datasource?.length > 0 || this.isFilterSame === false) {
              this.getToyotaDealerAtGlanceReport(); //initialise to empty object
              this.assignTabsAccordingToHrchy();
            }
            this.mapResponse();
          }

        } else {
          this.spinnerService.hideSpinner();

        }

      }, error: (error) => {
        console.error('Error handler:', error)
        this.spinnerService.hideSpinner();
      }
    });
  }

  getSelectedYear() {
    this.filterService.getSelectedValues()?.subscribe(val => {
      this.selectedFilter = val
    });
    return this.selectedFilter;
  }

  public tabChange(tabIndex: number) {
    this.currentTabIndex = tabIndex;
    const index = this.selectedTabIndex.findIndex(x => x === tabIndex);
    this.spinnerService.displaySpinner();
    if (index === -1) {
      this.selectedTabIndex.push(tabIndex);
      this.filtersSubscription?.unsubscribe();
      this.subscription?.unsubscribe();
      this.subscribeReportData()
    } else {
      this.spinnerService.hideSpinner();
    }
    this.displayProfileSection();
  }

  public async mapResponse() {
      const obj = getObjFromFilteredArray(this.selectedFilter);
      let levelId = obj['hierarchy']?.id;
      await this.getTabDataSource(this.currentTabIndex);
      let currentTableResponse = [];
      this.subscription = forkJoin(this.sources).subscribe({
        next: (resp) => {
          this.customReportsService.setPdfExcelIcons(true);
          let dataSources;
          let skill_set;
          if (this.currentTabIndex === 0) {
            if (resp[0]?.body)
              dataSources = [{
                "datasourceName": "profileDataSource",
                "tableData": resp[0]?.body,
                "removeLineBreak": true,
              }];
          }
          else if (this.currentTabIndex === 1 && levelId == HIERARCHY_TYPE.DEALER) {
            if (resp.length > 0 && resp[0].status === 200) {
              let reportData = resp[0]?.body[0]?.reportData;
              if (reportData?.length > 0) {
                const displayedColumns = this.getDisplayedColumnsForTLE(resp[0]?.body[0]?.reportHeaderData[0]);
                reportData.forEach((ele, index) => {
                  if (ele.sectionData?.length > 0) {
                    const obj = [{
                      "datasourceName": `toyota_loyalty ${index}`,
                      "tableData": ele.sectionData,
                      "sectionTitle": ele.SECTIONTITLE,
                      "sectionId": ele.SECTIONID,
                      "removeLineBreak": true,
                      'dateFormattingColumns': 'all',
                      "defaultUnitFlag": DATA_FORMAT_FLAGS.PercentWithTwoDecimal,
                    }];
                    if (index === 0) {
                      const header = `TOYOTA LOYALTY and ENGAGEMENT (${this.formattedSelectedFilter['monthYear']})`;
                      const ToyotaLoyaltyHeader = { ToyotaLoyaltyHeader: [{ [header]: '11' }] };
                      obj[0]["tableHeader"] = ToyotaLoyaltyHeader.ToyotaLoyaltyHeader;
                      obj[0]['columnHeaderName'] = this.obj(displayedColumns);
                      obj[0]["tableHeaderClass"] = "dxfw-tcuv-header",
                      obj[0]["isAllColumnsLeftAlignment"] = true,
                      obj[0]["isLeftAlignmentColumns"] = [0]
                    } else {
                      obj[0]['columnHeaderName'] = this.obj(displayedColumns);
                    }
                    currentTableResponse.push(obj);
                  }
                });
                console.log(currentTableResponse,'current table respppppppppp')
              }
            }
          }

          else if (this.currentTabIndex === 2 && levelId == HIERARCHY_TYPE.DEALER) {
            let reportData = resp[0]?.body[0]?.reportData;
            const isAllGuideLinesMet =  resp[0]?.body[0]?.reportHeaderData[0]?.ALL_GUIDE_LINES_MET;
            const displayedColumns = this.getDisplayedColumnsForAllSessions(resp[0]?.body[0]?.reportHeaderData[0], 'ALL_GUIDE_LINES_MET')
            const header = `TECHNICIAN SKILL LEVEL GUIDELINES (${this.formattedSelectedFilter['monthYear']})`;
            const TechnicianSkillHeader = { TechnicianSkillHeader: [{ [header]: '11' }] };
            if (reportData?.length > 0) {
              reportData.forEach((ele, index) => {
                if (ele.sectionData?.length > 0) {
                  const obj = [{
                    "datasourceName": `skill_set`,
                    "tableData": ele.sectionData,
                    "sectionTitle": ele.SECTIONTITLE,
                    "sectionId": ele.SECTIONID,
                    "tableHeaderClass": "dxfw-tcuv-header",
                    "removeLineBreak": true,
                    "widthClass": 'dxfw-custom-report-table-cell-width-center',
                  }];
                  if(index === 0){
                    obj[0]['columnHeaderName'] = this.obj(displayedColumns);
                    obj[0]['tableHeader'] = TechnicianSkillHeader.TechnicianSkillHeader,
                    obj[0]['isAllColumnsLeftAlignment'] = true,
                    obj[0]['isLeftAlignmentColumns'] = [0]
                  }

                  if(index === reportData?.length - 1){
                    obj[0]['allGuidLinesMet'] = `GridLinesMet : ${isAllGuideLinesMet}`
                  }

                  currentTableResponse.push(obj);
                }
              });
             
            }
          }
            else if ((this.currentTabIndex === 3 && levelId == HIERARCHY_TYPE.DEALER) || (this.currentTabIndex === 1 && levelId !== HIERARCHY_TYPE.DEALER)) {
              const reportData = resp[0]?.body[0]?.reportData;
              if (reportData.length > 0) {
                const displayedColumns = this.getDisplayedColumns(resp[0]?.body[0]?.reportHeaderData[0][0],'% Incr/Decr',levelId);
                reportData.forEach((ele, index) => {
                  if (ele.sectionData?.length > 0) {
                    const obj = [{
                      "datasourceName": `parts_data`,
                      "tableData": ele.sectionData,
                      "sectionTitle": ele.SECTIONTITLE,
                      "sectionId": ele.SECTIONID,
                      "removeLineBreak": true,
                      "widthClass": 'dxfw-custom-report-table-cell-width-center',
                      "columnBorders": [0, 3, 5],
                      'dateFormattingColumns': 'all'

                    }];
                    if (index === 0) {
                      const header = `SERVICE AND PARTS SALES (${this.formattedSelectedFilter['monthYear']})`;
                      const ServicePartsHeader = { ServicePartsHeader: [{ [header]: '9' }] };
                      obj[0]['tableHeader'] = ServicePartsHeader.ServicePartsHeader,
                      obj[0]['tableSubHeader'] = ToyotaDealerAtGlanceReport.ServicePartsSubHeader,
                      obj[0]['columnHeaderName'] = this.obj(displayedColumns);
                      obj[0]['hideColumnHeader'] = false;
                      obj[0]['isAllColumnsLeftAlignment'] = true,
                      obj[0]['isLeftAlignmentColumns'] = [0],
                      obj[0]['hideColumns'] = this.hideColums
                    } else {
                      obj[0]['hideColumnHeader'] = true;
                    }
                    currentTableResponse.push(obj);
                  }
                });

              }
            }
            else if ((this.currentTabIndex === 4 && levelId === HIERARCHY_TYPE.DEALER) || (this.currentTabIndex === 2 && levelId !== HIERARCHY_TYPE.DEALER)) {
              const reportData = resp[0]?.body[0]?.reportData;
              if (reportData.length > 0) {
                const displayedColumns = this.getDisplayedColumns(resp[0]?.body[0]?.reportHeaderData[0][0],'% of Prior MTD',levelId);
                reportData.forEach((ele, index) => {
                  if (ele.sectionData?.length > 0) {
                    const obj = [{
                      "datasourceName": `parts_access_data`,
                      "tableData": ele.sectionData,
                      "sectionTitle": ele.SECTIONTITLE,
                      "sectionId": ele.SECTIONID,
                      "removeLineBreak": true,
                      "widthClass": 'dxfw-custom-report-table-cell-width-center',
                      "columnBorders": [0, 3, 5],
                      'dateFormattingColumns': 'all'

                    }];
                    if (index === 0) {
                      const header = `PARTS AND ACCESSORY PURCHASES (${this.formattedSelectedFilter['monthYear']})`;
                      const PartsHeader = { PartsHeader: [{ [header]: this.headerCount }] };
                      obj[0]['tableHeader'] = PartsHeader.PartsHeader,
                      obj[0]['tableSubHeader'] = ToyotaDealerAtGlanceReport.PartsSubHeader,
                      obj[0]['hideColumnHeader'] = false;
                      obj[0]['columnHeaderName'] = this.obj(displayedColumns),
                      obj[0]['isAllColumnsLeftAlignment'] = true,
                      obj[0]['isLeftAlignmentColumns'] = [0]
                    } else if (index === 1) {
                      obj[0]["tableHeader"] = ToyotaDealerAtGlanceReport.PartsHeader1;
                      obj[0]['hideColumnHeader'] = true;
                    }
                    currentTableResponse.push(obj);
                  };
                });
              }
            }
    
            if (dataSources) {
              currentTableResponse.push(dataSources);
            }
            if (skill_set) {
              currentTableResponse.push(skill_set);
            }
        

            if (currentTableResponse.length !== 0) {
              this.toyotaDealerAtGlancejsonObj.tabList[this.currentTabIndex].datasource = currentTableResponse;
            }
            this.previousFilter = cloneDeep(this.selectedFilter);

            this.spinnerService.hideSpinner();
          }, error: (err) => {
            console.error('Error handler: mapping response', err);
            this.spinnerService.hideSpinner();
          }
        })

  }

 

  public getTabDataSource(tabIndex: number) {
    this.formattedSelectedFilter = getObjFromFilteredArray(this.selectedFilter);
    const obj = getObjFromFilteredArray(this.selectedFilter);
    const dateObj = getMonthAndYear(this.formattedSelectedFilter['monthYear']);
    const levelId = obj['hierarchy']?.id;
    const requestObj = {
      month: dateObj.searchMonth,
      year: dateObj.searchYear,
      hierarchy_level_type_rcid: levelId,
      hierarchy_level_member_rcid: obj['dealer'].MEMBER_CODE,
      view_id: this.getViewId(tabIndex, levelId)
    }
    if (tabIndex === 0) {
      this.sources = [this.customReportsApiService.getTCUVProfileInfo(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    }
    else if (tabIndex === 1 || tabIndex === 2 || tabIndex === 3 || tabIndex === 4) {
      this.sources = [this.customReportsApiService.getTdaagSlm(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    }

  }

  assignTabsAccordingToHrchy() {
    const obj = getObjFromFilteredArray(this.selectedFilter);
    const levelId = obj['hierarchy']?.id;
    if (levelId !== HIERARCHY_TYPE.DEALER) {
      const filteredTabList = this.toyotaDealerAtGlancejsonObj?.tabList.filter((tab, i) => i !== 1 && i !== 2);
      this.toyotaDealerAtGlancejsonObj.tabList = filteredTabList
    }
  }


  ngOnDestroy(): void {
    this.customReportsService.setPdfExcelIcons(false);
  }

  handleError() {
    return function (obsv: Observable<any>) {
      return obsv.pipe(
        catchError((err) => {
          this.spinnerService.hideSpinner();
          return err;
        })
      );
    };
  }

  public displayProfileSection() {
    this.isProfileTable = this.currentTabIndex === 0;
  }

  public getToyotaDealerAtGlanceReport() {
    this.toyotaDealerAtGlancejsonObj = {
      tabList: [
        {
          tabName: this.tabList[0],
          filterCondition: 'LA - A - 04636 - AN TOYOTA CERRITOS',
          multiDataSources: true,
          datasource: []
        },
        {
          tabName: this.tabList[1],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[2],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[3],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[4],
          filterCondition: '',
          datasource: []
        }
      
      ]
    }
  }

  public getViewId(tabIndex: number, level_type) {
    let viewIds = [];
    if (level_type === HIERARCHY_TYPE.DEALER) {
      viewIds = ['profile-info', 'slm-info', 'skillset', 'partsdata', 'partsaccessdata'];
    } else {
      viewIds = ['profile-info', 'partsdata', 'partsaccessdata'];
    }
    return `${viewIds[tabIndex]}`;
  }


  getDisplayedColumnsForTLE(reportHeaderData: any) {
    reportHeaderData['ROWTYPE'] = 'ROWTYPE';
    reportHeaderData['PYYEAR'] = reportHeaderData.PYEAR;
    reportHeaderData['CYYEAR'] = reportHeaderData.CYEAR;
    const data = _.pick(reportHeaderData, 'ROWTYPE', 'PREVPREVMONTHPARTVALUE', 'PREVMONTHPARTVALUE', 'CURRMONTHPART', 'PREVYEARFORMATVALUE', 'CURRYEARFORMATVALUE');
    return data;
  }
  getDisplayedColumnsForAllSessions(reportHeaderData: any, columnName: string) {
    reportHeaderData['TITLE'] = '';
    const displayedColumns = _.omit(reportHeaderData,[columnName]);
    return displayedColumns;
  }

  getDisplayedColumns(reportHeaderData: any, columnName, levelId) {
    reportHeaderData['TITLE'] = '';
    reportHeaderData['PMYEAR'] = reportHeaderData?.PYEAR;
    reportHeaderData['CMYEAR'] = reportHeaderData?.CYEAR;
    reportHeaderData['CMIncr'] = columnName;
    reportHeaderData['PYYEAR'] = reportHeaderData?.PYEAR;
    reportHeaderData['CYYEAR'] = reportHeaderData?.CYEAR;
    reportHeaderData['DEALER'] = 'DEALER';
    reportHeaderData['DISTRICT'] = 'DISTRICT';
    reportHeaderData['REGION'] = 'REGION';
    reportHeaderData['NATIONAL'] = 'NATIONAL';
    let displayedColumns;
    if(levelId === HIERARCHY_TYPE.NATIONAL){
      this.hideColums = ['DLR_YTD_PCT_CHG','DIST_YTD_PCT_CHG','REG_YTD_PCT_CHG'];
      this.headerCount = 7;
      displayedColumns = _.pick(reportHeaderData, 'TITLE', 'PMYEAR', 'CMYEAR', 'CMIncr', 'PYYEAR', 'CYYEAR', 'NATIONAL');
    } else if(levelId === HIERARCHY_TYPE.REGION){
      this.headerCount = 8;
      this.hideColums = ['DLR_YTD_PCT_CHG','DIST_YTD_PCT_CHG']
      displayedColumns = _.pick(reportHeaderData, 'TITLE', 'PMYEAR', 'CMYEAR', 'CMIncr', 'PYYEAR', 'CYYEAR', 'REGION','NATIONAL');
    } else if(levelId === HIERARCHY_TYPE.DEALER){
      this.headerCount = 9;
      this.hideColums = ['NAT_YTD_PCT_CHG']
      displayedColumns = _.pick(reportHeaderData, 'TITLE', 'PMYEAR', 'CMYEAR', 'CMIncr', 'PYYEAR', 'CYYEAR', 'DEALER', 'DISTRICT', 'REGION');
    } else if(levelId === HIERARCHY_TYPE.DISTRICT){
      this.headerCount = 9;
      this.hideColums = ['DLR_YTD_PCT_CHG']
      displayedColumns = _.pick(reportHeaderData, 'TITLE', 'PMYEAR', 'CMYEAR', 'CMIncr', 'PYYEAR', 'CYYEAR', 'DISTRICT', 'REGION', 'NATIONAL');
  }
    return displayedColumns;
  }

}



