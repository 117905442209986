import { COMPANY_TYPE, HIERARCHY_TYPE } from "src/app/constants/constants";

export const MockCustomReportListResponseDEV = [
  {
    name: 'Lexus PO snapshot executive summary',
    id: 1
  },
  {
    name: 'Lexus Dealer At a Glance',
    id: 2
  },
  {
    name: 'TCUV Dealer At a Glance',
    id: 3
  },
  {
    name: 'Toyota Dealer At A Glance',
    id: 4
  },
  {
    name: 'Lexus Sales At A Glance Report',
    id: 5
  },
  {
    name: 'GST Toyota Dealer At A Glance',
    id: 6
  },
  {
    name: 'TTFO - Dealer at a Glance Report',
    id: 7
  },
  {
    name: 'Dealership Performance Summary',
    id: 8
  },
  {
    name: 'Parts Department',
    id: 9
  },
  {
    name: 'Service Dept Expense And Sales Analysis',
    id: 10
  }
];

export interface PeriodicElement {
  position: string;
  lyear20: string;
  lyear21: string;
  lyear22: string;
  lyear23: string,
  lchange23: string;
  year20: string;
  year21: string;
  year22: string;
  year23: string,
  change23: string;
}

export const ELEMENT_DATA: PeriodicElement[] = [
  { position: 'WESTERN', year20: '16.009', year21: '16.009', year22: '16.009', year23: '16.009', change23: '16.009', lyear20: '16.009', lyear21: '16.009', lyear22: '16.009', lyear23: '16.009', lchange23: '16.009' },
  { position: 'CENTRAL', year20: '16.009', year21: '16.009', year22: '16.009', year23: '16.009', change23: '16.009', lyear20: '16.009', lyear21: '16.009', lyear22: '16.009', lyear23: '16.009', lchange23: '16.009' },
  { position: 'EASTERN', year20: '16.009', year21: '16.009', year22: '16.009', year23: '16.009', change23: '16.009', lyear20: '16.009', lyear21: '16.009', lyear22: '16.009', lyear23: '16.009', lchange23: '16.009' },
  { position: 'SOUTHERN', year20: '16.009', year21: '16.009', year22: '16.009', year23: '16.009', change23: '16.009', lyear20: '16.009', lyear21: '16.009', lyear22: '16.009', lyear23: '16.009', lchange23: '16.009' },
  { position: 'NATIONAL', year20: '16.009', year21: '16.009', year22: '16.009', year23: '16.009', change23: '16.009', lyear20: '16.009', lyear21: '16.009', lyear22: '16.009', lyear23: '16.009', lchange23: '16.009' }
];

export const SingleProfileTable = [{
  "Dealership": "TOYOTA OF LONG BEACH",
  "Region": "Los Angeles",
  "Address": "4401 E. PACIFIC COAST HWY",
  "District": "LA District 1: Los Angeles/No. Orange County",
  "City": "LONG BEACH Market",
  "DistrictManager": "Becky Levy",
}]

export const MockTopTenDealersResponsDEV = {

  "CERTIFIED-PRE-OWNED": [
    {
      "RANK": 1,
      "AREA": "southern",
      "DEALER": "JOHNSON LEXUS OF DURHAM",
      "YTD": 115,
    },
    {
      "RANK": 2,
      "AREA": "Eastern",
      "DEALER": "JOHNSON LEXUS OF RALEIGH ",
      "YTD": 97,
    }
  ],
  "TOTAL-LEXUS PRE-OWNED": [
    {
      "RANK": 1,
      "AREA": "SEWELL LEXUS ",
      "DEALER": "One T D1",
      "YTD": 11,
    },
    {
      "RANK": 2,
      "AREA": "Two",
      "DEALER": "One T D2",
      "YTD": 12,
    }
  ]
}

export const LexusDealerAtGlancePartsMockData = {
  body: [
    {
      "reportData": [
        {
          "(May 2019)": "Tires Penetration",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Tires Penetration Missed Opportunity in $",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Oil Sales Penetration",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Oil Sales Penetration Missed Opportunity in $",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Lexus Wipers Penetration",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Lexus Wipers Penetration Missed Opportunity in $",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Chemicals Penetration",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Chemicals Penetration Missed Opportunity in $",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        }]
    }
  ],
  status: 200
}

export const LexusSalesAtGlanceMockData = {
  "Scores": {
    "tableData": [
      {
        "SCORE (JAN 01, 2020)": "Sales OSAT Score",
        "OBJ": "95.0",
        "JAN 20": "100.0",
        "DEC 19": "97.6",
        "NOV 19": "98.5",
        "OCT 19": "96.5",
        "SEP 19": "99.7",
        "AUG 19": "94.7",
        "DLR": "100.0",
        "DIST": "94.5",
        "AREA ": "95.2",
        "NAT'L": "95.0",
      },
      {
        "SCORE (JAN 01, 2020)": "L/Cert OSAT Score",
        "OBJ": "89.0",
        "JAN 20": "n/a",
        "DEC 19": "100.0",
        "NOV 19": "97.5",
        "OCT 19": "100.0",
        "SEP 19": "90.0",
        "AUG 19": "100.0",
        "DLR": "n/a",
        "DIST": "90.5",
        "AREA ": "90.5",
        "NAT'L": "91.6"
      },
    ],
    "tableHeader": [
      {
        "GUEST SATISFACTION": '2',
        "DEALER 6-MONTH HISTORY": '6',
        "YEAR TO DATE": '4',
      }
    ],
  },
  "Scores_1": {
    "tableData": [
      {
        "RETAIL % OBJ (MAY 2023)": "L/Cert Retail Sales",
        "OBJ": "95.0",
        "MAY 23": "15.0",
        "APR 23": "85.0",
        "MAR 23": "113.3",
        "FEB 23": "180.0",
        "JAN 23": "140.0",
        "DEC 22": "223.0",
        "DLR": "92.0",
        "DIST": "100.8",
        "AREA ": "107.7",
        "NAT'L": "108.4"
      },
      {
        "RETAIL % OBJ (MAY 2023)": "New Retail Sales",
        "OBJ": "95.0",
        "MAY 23": "64.2",
        "APR 23": "55.7",
        "MAR 23": "66.0",
        "FEB 23": "71.7",
        "JAN 23": "78.4",
        "DEC 22": "72.8",
        "DLR": "66.4",
        "DIST": "100.5",
        "AREA ": "102.1",
        "NAT'L": "106.2"
      }
    ],
    "tableHeader": [
      {
        "SALES-VOLUME": '2',
        "DEALER 6-MONTH HISTORY": '6',
        "YEAR TO DATE": '4',
      }
    ]
  },
  "Sales": {
    "tableData": [
      {
        " ": "Dealer New Vehicle Sales",
        "MTD OBJ": "95",
        "MAY 23": "61",
        "APR 23": "63",
        "MAR 23": "59",
        "FEB 23": "51",
        "JAN 23": "61",
        "DEC 22": "67",
        "%MTD OBJ": "64.20",
        "SALES AS % AREA": "101.7",
        "YTD": "100.5",
        "LYTD": "103.0",
        "  ": '-122'
      },
      {
        " ": "Dealer TTL PO Sales",
        "MTD OBJ": "20",
        "MAY 23": "3",
        "APR 23": "17",
        "MAR 23": "17",
        "FEB 23": "18",
        "JAN 23": "14",
        "DEC 22": "29",
        "%MTD OBJ": "15.0",
        "SALES AS % AREA": "101.7",
        "YTD": "100.5",
        "LYTD": "103.0",
        "  ": '-122'
      },
      {
        " ": "Dealer CPO Sales",
        "MTD OBJ": "20",
        "MAY 23": "3",
        "APR 23": "17",
        "MAR 23": "17",
        "FEB 23": "18",
        "JAN 23": "14",
        "DEC 22": "29",
        "%MTD OBJ": "15.0",
        "SALES AS % AREA": "101.7",
        "YTD": "100.5",
        "LYTD": "103.0",
        "  ": '-16'
      },
    ],
    "tableHeader": [
      {
        "SALES(MAY 2023)": '2',
        "DEALER 6-MONTH HISTORY": '6',
        "MTD": '2',
        "YTD ": '2',
        "UNITS +/-ELITE": ''
      }
    ]
  },
  "SalesByModel": {
    "tableData": [
      {
        " ": "Total All Series",
        "2023   ": "61",

        "2022   ": "68",
        "%CHANGE   ": "-10.3%",
        "DLR   ": "55.9",
        "DIST   ": "78.5",
        "AREA   ": "77.8",
        "DLR    ": "21.4",
        "DIST    ": "6.6",
        "AREA    ": "6.9",
        "2023    ": "292",
        "2022    ": "343",
        "%CHANGE    ": "-16.1%",


      },
      {
        " ": "IS",
        "2023   ": "5",
        "2022   ": "10",
        "%CHANGE   ": "-50.0%",
        "DLR   ": "55.5",
        "DIST   ": "79.5",
        "AREA   ": "75.9",
        "DLR    ": "22.7",
        "DIST    ": "6.4",
        "AREA    ": "7.7",
        "2023    ": "21",
        "2022    ": "38",
        "%CHANGE    ": "-44.7%",
      },
      {
        " ": "RC",
        "2023   ": 0,

        "2022   ": "1",
        "%CHANGE   ": "-100.0%",
        "DLR   ": "0.0",
        "DIST   ": "46.2",
        "AREA   ": "36.8",
        "DLR    ": "0.0",
        "DIST    ": "25.5",
        "AREA    ": "47.5",
        "2023    ": 0,
        "2022    ": "2",
        "%CHANGE    ": "-100.0%",


      },
      {
        " ": "RC-F",
        "2023   ": 0,
        "2022   ": 0,
        "%CHANGE   ": "0.0%",
        "DLR   ": "0.0",
        "DIST   ": "50.0",
        "AREA   ": "20.0",
        "DLR    ": "0.0",
        "DIST    ": "61.2",
        "AREA    ": 1,
        "2023    ": 0,
        "2022    ": "2",
        "%CHANGE    ": "0.0%",
      },
    ],
    "tableHeader": [
      {
        "SALES BY MODEL (MAY 2023)": '1',
        "MONTH END SALES": '3',
        "MONTH END SALES TO AVAILABILITY": '3',
        "MONTH END DAYS SUPPLY": '3',
        " YTD SALES": '3'
      }
    ]
  },
  "marketShare": {
    "tableData": [
      {
        " ": "Total Luxury Car",
        "DLR     ": "10.0",
        "METRO": "0.0",
        "AREA ": "7.6",
        "DLR  ": "9.2",
        "METRO   ": "0.0",
        "AREA   ": "7.3",
        "DLR    ": "-1.3",
        "METRO    ": "-9.5",
        "AREA    ": "-1.6",
        "    ": "-",
        "     ": "TES",
        "  ": "-179"
      },
      {
        " ": "Total Luxury SUV",
        "DLR     ": "12.4",
        "METRO": "0.0%",
        "AREA ": "10.7",
        "DLR  ": "12.6",
        "METRO   ": "0.0",
        "AREA   ": "11.1",
        "DLR    ": "-1.1",
        "METRO    ": "-12.4",
        "AREA    ": "-0.7",
        "    ": "-",
        "     ": "TES",
        "  ": "-171"
      },
      {
        " ": "Total",
        "DLR     ": "11.5",
        "METRO": "0.0%",
        "AREA ": "9.6",
        "DLR  ": "11.4",
        "METRO   ": "0.0",
        "AREA   ": "9.8",
        "DLR    ": "-1.0",
        "METRO    ": "-11.2",
        "AREA    ": "-0.9",
        "    ": "105.2",
        "     ": "TES",
        "  ": "-350"
      },
    ],
    "tableHeader": [
      {
        "MARKET SHARE (JUL 2023)": '1',
        "ROLLING 12 MONTHS": '3',
        "YTD": '3',
        "YTD %CHANGE 2022": '3',
        "SALES EFFICIENCY": '',
        "MARKET LEADER": '',
        "UNITS +/- LEADER": ''
      }
    ]
  },
  "grossProfit": {
    "tableData": [
      {
        "    ": "Total All Series",
        "DLR": "$4,286",
        "DIST AVG": "$2,527",
        "AREA AVG": "$1,936",
        "DLR ": "$5,644",
        "DIST AVG ": "$2,831",
        "AREA AVG ": "$2,267",
        "DLR  ": "$7,616",
        "DIST AVG  ": "$5,294",
        "AREA AVG  ": "$4,205",
        "DLR   ": "$8,712",
        "DIST AVG    ": "$5,436",
        "AREA AVG    ": "$4,532"
      },
      {
        "    ": "IS",
        "DLR": "$2,670",
        "DIST AVG": "$1,318",
        "AREA AVG": "$1,172",
        "DLR ": "$3,241",
        "DIST AVG ": "$1,242",
        "AREA AVG ": "$1,171",
        "DLR  ": "$2,670",
        "DIST AVG  ": "$2,115",
        "AREA AVG  ": "$1,531",
        "DLR   ": "$3,306",
        "DIST AVG    ": "$1,940",
        "AREA AVG    ": "$1,515"

      },
      {
        "    ": "RC",
        "DLR": "$0",
        "DIST AVG": "$3,607",
        "AREA AVG": "$1,339",
        "DLR ": "$0",
        "DIST AVG ": "$1,723",
        "AREA AVG ": "$1,206",
        "DLR  ": "$0",
        "DIST AVG  ": "$4,018",
        "AREA AVG  ": "$1,654",
        "DLR   ": "$0",
        "DIST AVG    ": "$2,172",
        "AREA AVG    ": "$1,492"
      },
    ],
    "tableHeader": [
      {
        "GROSS PROFIT (MAY 2023)": 15
      }
    ],
    "tableSubHeader":
      [
        {
          "        ": 1,
          "GROSS PROFIT BEFORE INCENTIVES $PUR/L": 6,
          "GROSS PROFIT AFTER INCENTIVES $PUR/L": 6,
        },
      ],
    "tableSubHeader2":
      [
        {
          "             ": 1,
          "MTD": 3,
          "YTD": 3,
          "MTD ": 3,
          "YTD ": 3,
        }
      ],
  }
};

export const tfsnationalDprMockData ={
  status:200,
  body:{
  "combined": [
      {
          "reportData": [
              {
                  "SECTIONNO": "1",
                  "SECTIONTITLE": "Overall Gross & Contract Penetration",
                  "sectionData": [
                      {
                          "SECTIONID": 1,
                          "SECTIONTITLE": "Overall Gross & Contract Penetration",
                          "ACCT_NO": "B9003",
                          "ACCT_TITLE": "Combined FS Office Gross PRU",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "1719.9380",
                          "UNITS_FLAG": "81",
                          "SECTIONNO": 1,
                          "ACCTSEQNO": 4
                      },
                      {
                          "SECTIONID": 1,
                          "SECTIONTITLE": "Overall Gross & Contract Penetration",
                          "ACCT_NO": "B9006",
                          "ACCT_TITLE": "Combined Finance Reserve Per Contract Sold",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "828.6210",
                          "UNITS_FLAG": "81",
                          "SECTIONNO": 1,
                          "ACCTSEQNO": 7
                      },
                      {
                          "SECTIONID": 1,
                          "SECTIONTITLE": "Overall Gross & Contract Penetration",
                          "ACCT_NO": "B10024",
                          "ACCT_TITLE": "Combined EWU Penetration (New Lease Only)",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "17.9260",
                          "UNITS_FLAG": "810",
                          "SECTIONNO": 1,
                          "ACCTSEQNO": 12
                      }
                  ]
              },
              {
                  "SECTIONNO": "2",
                  "SECTIONTITLE": "FSD Income/Chargeback Analysis",
                  "sectionData": [
                      {
                          "SECTIONID": 2,
                          "SECTIONTITLE": "FSD Income/Chargeback Analysis",
                          "ACCT_NO": "B9013",
                          "ACCT_TITLE": "Combined FS Department Penetration",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "0.0000",
                          "UNITS_FLAG": "810",
                          "SECTIONNO": 2,
                          "ACCTSEQNO": 16
                      },
                      {
                          "SECTIONID": 2,
                          "SECTIONTITLE": "FSD Income/Chargeback Analysis",
                          "ACCT_NO": "B9015",
                          "ACCT_TITLE": "Combined FS Department Chargebacks PRU",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "1152.7330",
                          "UNITS_FLAG": "81",
                          "SECTIONNO": 2,
                          "ACCTSEQNO": 18
                      },
                      {
                          "SECTIONID": 2,
                          "SECTIONTITLE": "FSD Income/Chargeback Analysis",
                          "ACCT_NO": "B9014",
                          "ACCT_TITLE": "Combined FS Department Chargebacks",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "1339371.0240",
                          "UNITS_FLAG": "81",
                          "SECTIONNO": 2,
                          "ACCTSEQNO": 17
                      }
                  ]
              }
          ],
          "reportHeaderData": [
              {
                  "COMPANY_CODE": "15",
                  "COMPANY_NAME": "Lexus",
                  "COMPANY": "15 - Lexus",
                  "REGION_CODE": "330",
                  "REGION": "330 - Eastern",
                  "REGION_NAME": "Eastern",
                  "REGION_ALPHA_CODE": "EAS     "
              },
              {
                  "COMPANY_CODE": "15",
                  "COMPANY_NAME": "Lexus",
                  "COMPANY": "15 - Lexus",
                  "REGION_CODE": "390",
                  "REGION": "390 - 390th",
                  "REGION_NAME": "390th",
                  "REGION_ALPHA_CODE": "390TH   "
              },
              {
                  "COMPANY_CODE": "15",
                  "COMPANY_NAME": "Lexus",
                  "COMPANY": "15 - Lexus",
                  "REGION_CODE": "310",
                  "REGION": "310 - Western",
                  "REGION_NAME": "Western",
                  "REGION_ALPHA_CODE": "WES     "
              },
              {
                  "COMPANY_CODE": "15",
                  "COMPANY_NAME": "Lexus",
                  "COMPANY": "15 - Lexus",
                  "REGION_CODE": "320",
                  "REGION": "320 - Central",
                  "REGION_NAME": "Central",
                  "REGION_ALPHA_CODE": "CEN     "
              },
              {
                  "COMPANY_CODE": "15",
                  "COMPANY_NAME": "Lexus",
                  "COMPANY": "15 - Lexus",
                  "REGION_CODE": "340",
                  "REGION": "340 - Southern",
                  "REGION_NAME": "Southern",
                  "REGION_ALPHA_CODE": "SOU     "
              }
          ]
      }
  ],
  "new": [
      {
          "reportData": [
              {
                  "SECTIONNO": "2",
                  "SECTIONTITLE": "Finance Analysis",
                  "sectionData": [
                      {
                          "SECTIONID": 2,
                          "SECTIONTITLE": "Finance Analysis",
                          "ACCT_NO": "B9023",
                          "ACCT_TITLE": "Number Of New Veh Finance & Lease Contracts Sold",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "393.8520",
                          "UNITS_FLAG": "84",
                          "SECTIONNO": 2,
                          "ACCTSEQNO": 4
                      },
                      {
                          "SECTIONID": 2,
                          "SECTIONTITLE": "Finance Analysis",
                          "ACCT_NO": "B9024",
                          "ACCT_TITLE": "Number of Lease Contracts Sold",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "153.3440",
                          "UNITS_FLAG": "84",
                          "SECTIONNO": 2,
                          "ACCTSEQNO": 5
                      }
                  ]
              },
              {
                  "SECTIONNO": "3",
                  "SECTIONTITLE": "Service Contract Analysis",
                  "sectionData": [
                      {
                          "SECTIONID": 3,
                          "SECTIONTITLE": "Service Contract Analysis",
                          "ACCT_NO": "B9036",
                          "ACCT_TITLE": "Number of Service Contracts Sold",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "146.2830",
                          "UNITS_FLAG": "84",
                          "SECTIONNO": 3,
                          "ACCTSEQNO": 17
                      }
                  ]
              },
              {
                  "SECTIONNO": "5",
                  "SECTIONTITLE": "Gap Insurance Analysis",
                  "sectionData": [
                      {
                          "SECTIONID": 5,
                          "SECTIONTITLE": "Gap Insurance Analysis",
                          "ACCT_NO": "B9052",
                          "ACCT_TITLE": "Number of GAP Insurance Contracts Sold",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "45.6390",
                          "UNITS_FLAG": "84",
                          "SECTIONNO": 5,
                          "ACCTSEQNO": 33
                      },
                      {
                          "SECTIONID": 5,
                          "SECTIONTITLE": "Gap Insurance Analysis",
                          "ACCT_NO": "B9054",
                          "ACCT_TITLE": "GAP Insurance Contract Penetration",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "18.9760",
                          "UNITS_FLAG": "810",
                          "SECTIONNO": 5,
                          "ACCTSEQNO": 35
                      }
                  ]
              },
              {
                  "SECTIONNO": "6",
                  "SECTIONTITLE": "Excess Wear & Use Analysis",
                  "sectionData": [
                      {
                          "SECTIONID": 6,
                          "SECTIONTITLE": "Excess Wear & Use Analysis",
                          "ACCT_NO": "B10025",
                          "ACCT_TITLE": "Excess Wear & Use Gross Per Contract Sold",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "532.8740",
                          "UNITS_FLAG": "81",
                          "SECTIONNO": 6,
                          "ACCTSEQNO": 42
                      },

                      {
                          "SECTIONID": 6,
                          "SECTIONTITLE": "Excess Wear & Use Analysis",
                          "ACCT_NO": "B10031",
                          "ACCT_TITLE": "Number of Excess Wear & Use Contracts Sold",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "34.1560",
                          "UNITS_FLAG": "84",
                          "SECTIONNO": 6,
                          "ACCTSEQNO": 41
                      },

                  ]
              },
              {
                  "SECTIONNO": "7",
                  "SECTIONTITLE": "Prepaid Maintenance Analysis",
                  "sectionData": [
                      {
                          "SECTIONID": 7,
                          "SECTIONTITLE": "Prepaid Maintenance Analysis",
                          "ACCT_NO": "B9060",
                          "ACCT_TITLE": "Number of Prepaid Maintenance Contracts Sold",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "158.0980",
                          "UNITS_FLAG": "84",
                          "SECTIONNO": 7,
                          "ACCTSEQNO": 49
                      }
                  ]
              },
              {
                  "SECTIONNO": "9",
                  "SECTIONTITLE": "Key Replacement",
                  "sectionData": [
                      {
                          "SECTIONID": 10,
                          "SECTIONTITLE": "Key Replacement",
                          "ACCT_NO": "B10121",
                          "ACCT_TITLE": "Number of Key Replacement Protection Contracts Sold",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "13.8160",
                          "UNITS_FLAG": "84",
                          "SECTIONNO": 9,
                          "ACCTSEQNO": 76
                      }
                  ]
              },
              {
                  "SECTIONNO": "10",
                  "SECTIONTITLE": "Aftermarket Analysis",
                  "sectionData": [
                      {
                          "SECTIONID": 1,
                          "SECTIONTITLE": "Aftermarket Analysis",
                          "ACCT_NO": "B9020",
                          "ACCT_TITLE": "Total New Veh  Aftermarket Gross Profit",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "255499.8610",
                          "UNITS_FLAG": "81",
                          "SECTIONNO": 10,
                          "ACCTSEQNO": 1
                      }
                  ]
              },
              {
                  "SECTIONNO": "11",
                  "SECTIONTITLE": "FSD Income/Charge Back Analysis",
                  "sectionData": [
                      {
                          "SECTIONID": 8,
                          "SECTIONTITLE": "FSD Income/Charge Back Analysis",
                          "ACCT_NO": "B9070",
                          "ACCT_TITLE": "FS Department Contracts Sold",
                          "01 - West": "1752.3200",
                          "02 - East": "1506.2830",
                          "03 - Central": "1642.3670",
                          "NATLAVG": "1148.6560",
                          "UNITS_FLAG": "84",
                          "SECTIONNO": 11,
                          "ACCTSEQNO": 59
                      },
                  ]
              }
          ],
          "reportHeaderData": [
              {
                  "COMPANY_CODE": "15",
                  "COMPANY_NAME": "Lexus",
                  "COMPANY": "15 - Lexus",
                  "REGION_CODE": "330",
                  "REGION": "330 - Eastern",
                  "REGION_NAME": "Eastern",
                  "REGION_ALPHA_CODE": "EAS     "
              },
              {
                  "COMPANY_CODE": "15",
                  "COMPANY_NAME": "Lexus",
                  "COMPANY": "15 - Lexus",
                  "REGION_CODE": "390",
                  "REGION": "390 - 390th",
                  "REGION_NAME": "390th",
                  "REGION_ALPHA_CODE": "390TH   "
              },
              {
                  "COMPANY_CODE": "15",
                  "COMPANY_NAME": "Lexus",
                  "COMPANY": "15 - Lexus",
                  "REGION_CODE": "310",
                  "REGION": "310 - Western",
                  "REGION_NAME": "Western",
                  "REGION_ALPHA_CODE": "WES     "
              },
              {
                  "COMPANY_CODE": "15",
                  "COMPANY_NAME": "Lexus",
                  "COMPANY": "15 - Lexus",
                  "REGION_CODE": "320",
                  "REGION": "320 - Central",
                  "REGION_NAME": "Central",
                  "REGION_ALPHA_CODE": "CEN     "
              },
              {
                  "COMPANY_CODE": "15",
                  "COMPANY_NAME": "Lexus",
                  "COMPANY": "15 - Lexus",
                  "REGION_CODE": "340",
                  "REGION": "340 - Southern",
                  "REGION_NAME": "Southern",
                  "REGION_ALPHA_CODE": "SOU     "
              }
          ]
      }
  ],
  "used": [
      {
          "reportData": [],
          "reportHeaderData": [
              {
                  "COMPANY_CODE": "15",
                  "COMPANY_NAME": "Lexus",
                  "COMPANY": "15 - Lexus",
                  "REGION_CODE": "310",
                  "REGION": "310 - Western",
                  "REGION_NAME": "Western",
                  "REGION_ALPHA_CODE": "WES     "
              },
              {
                  "COMPANY_CODE": "15",
                  "COMPANY_NAME": "Lexus",
                  "COMPANY": "15 - Lexus",
                  "REGION_CODE": "320",
                  "REGION": "320 - Central",
                  "REGION_NAME": "Central",
                  "REGION_ALPHA_CODE": "CEN     "
              },
              {
                  "COMPANY_CODE": "15",
                  "COMPANY_NAME": "Lexus",
                  "COMPANY": "15 - Lexus",
                  "REGION_CODE": "340",
                  "REGION": "340 - Southern",
                  "REGION_NAME": "Southern",
                  "REGION_ALPHA_CODE": "SOU     "
              },
              {
                  "COMPANY_CODE": "15",
                  "COMPANY_NAME": "Lexus",
                  "COMPANY": "15 - Lexus",
                  "REGION_CODE": "330",
                  "REGION": "330 - Eastern",
                  "REGION_NAME": "Eastern",
                  "REGION_ALPHA_CODE": "EAS     "
              },
              {
                  "COMPANY_CODE": "15",
                  "COMPANY_NAME": "Lexus",
                  "COMPANY": "15 - Lexus",
                  "REGION_CODE": "390",
                  "REGION": "390 - 390th",
                  "REGION_NAME": "390th",
                  "REGION_ALPHA_CODE": "390TH   "
              }
          ]
      }
  ]
}
}

export const PartsDepartmentAnalysisMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "4143",
          "DEALER_NAME": "City Toyota",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Sales",
          "sectionData": [
            {

              "IS_USER_INPUT": false,
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NO": "B9659",
              "ACCT_NO_GP": null,
              "acct_name": "P&A Cust Mech Sls",
              "DLR_CY": "6205260.0000",
              "DLR_GP_CY": null,
              "DLR_PY": "4342484.0000",
              "DLR_GP_PY": null,
              "DEALER_DIFF": "42.89",
              "DIST_CY": "3218154",
              "DIST_GP_CY": null,
              "DIST_PY": "2786534",
              "DIST_GP_PY": null,
              "DISTRICT_DIFF": "15.48",
              "RGN_CY": "2901781",
              "RGN_GP_CY": null,
              "RGN_PY": "2591865",
              "RGN_GP_PY": null,
              "REGION_DIFF": "11.95",
              "NTNL_CY": "2192125",
              "NTNL_GP_CY": null,
              "NTNL_PY": "2007870",
              "NTNL_GP_PY": null,
              "NATIONAL_DIFF": "9.17"
            },
            {

              "IS_USER_INPUT": false,
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 2,
              "ACCT_NO": "4710S",
              "ACCT_NO_GP": null,
              "acct_name": "P&A Body Shop Sls",
              "DLR_CY": "0.0000",
              "DLR_GP_CY": null,
              "DLR_PY": "0.0000",
              "DLR_GP_PY": null,
              "DEALER_DIFF": "0.00",
              "DIST_CY": "152028",
              "DIST_GP_CY": null,
              "DIST_PY": "135914",
              "DIST_GP_PY": null,
              "DISTRICT_DIFF": "11.85",
              "RGN_CY": "131857",
              "RGN_GP_CY": null,
              "RGN_PY": "114463",
              "RGN_GP_PY": null,
              "REGION_DIFF": "15.19",
              "NTNL_CY": "364408",
              "NTNL_GP_CY": null,
              "NTNL_PY": "336516",
              "NTNL_GP_PY": null,
              "NATIONAL_DIFF": "8.28"
            }
          ]
        },
        {
          "SECTIONID": "2",
          "SECTIONTITLE": "Gross Profit $/%",
          "sectionData": [
            {
              "IS_USER_INPUT": false,
              "SECTIONID": 2,
              "SECTIONTITLE": "Gross Profit $/%",
              "SECTIONLINENO": 1,
              "ACCT_NO": "B9660",
              "ACCT_NO_GP": "B8046",
              "acct_name": "Total  P & A Mechanical/TC/TC+ GP",
              "DLR_CY": "1877303.0000",
              "DLR_GP_CY": "38.0",
              "DLR_PY": "1316164.0000",
              "DLR_GP_PY": "38.1",
              "DEALER_DIFF": "42.63",
              "DIST_CY": "1108915",
              "DIST_GP_CY": "42.2",
              "DIST_PY": "959660",
              "DIST_GP_PY": "42.0",
              "DISTRICT_DIFF": "15.55",
              "RGN_CY": "987690",
              "RGN_GP_CY": "41.5",
              "RGN_PY": "871637",
              "RGN_GP_PY": "40.8",
              "REGION_DIFF": "13.31",
              "NTNL_CY": "679932",
              "NTNL_GP_CY": "0.4",
              "NTNL_PY": "622945",
              "NTNL_GP_PY": "0.4",
              "NATIONAL_DIFF": "9.14"
            },
            {
              "IS_USER_INPUT": false,
              "SECTIONID": 2,
              "SECTIONTITLE": "Gross Profit $/%",
              "SECTIONLINENO": 2,
              "ACCT_NO": "4710T",
              "ACCT_NO_GP": "B8049",
              "acct_name": "Body Shop P & A  GP",
              "DLR_CY": "0.0000",
              "DLR_GP_CY": "0.0",
              "DLR_PY": "0.0000",
              "DLR_GP_PY": "0.0",
              "DEALER_DIFF": "0.00",
              "DIST_CY": "54653",
              "DIST_GP_CY": "35.9",
              "DIST_PY": "49471",
              "DIST_GP_PY": "36.4",
              "DISTRICT_DIFF": "10.47",
              "RGN_CY": "44929",
              "RGN_GP_CY": "34.1",
              "RGN_PY": "39523",
              "RGN_GP_PY": "34.5",
              "REGION_DIFF": "13.67",
              "NTNL_CY": "111221",
              "NTNL_GP_CY": "0.3",
              "NTNL_PY": "103238",
              "NTNL_GP_PY": "0.3",
              "NATIONAL_DIFF": "7.73"
            },
            {
              "sectionNo": 3,
              "sectionTitle": "Inventory",
              "sectionData": [
                {
                  "Line": "1",
                  "Gross_profit": "P & A Inventory",
                  "YTD": "111",
                  "GP_Sales": "2.2%",
                  "Prior_YTD": "342102",
                  "GP_Sales1": "42.4%",
                  "+/-%": "-100",
                  "K_YTD": "111",
                  "K_GP_Sales": "0.0%",
                  "K_Prior_YTD": "720831",
                  "K_GP_Sales1": "38.7%",
                  "K_+/-%": "-100",
                  "KA_YTD": "111",
                  "KA_GP_Sale": "0.0%",
                  "KA_Prior_YTD": "720831",
                  "KA_GP_Sale1": "37.8%",
                  "KA_+/-%": "-100",
                  "N_YTD": "111",
                  "N_GP_Sale": "0.4%",
                  "N_Prior_YTD": "720831",
                  "N_GP_Sale1": "0.4%",
                  "N_+/-%": "-100",
                  // "IS_USER_INPUT": false
                },
                {
                  "Line": "2",
                  "Gross_profit": "Inv.- P and A - Other",
                  "YTD": "111",
                  "GP_Sales": "NULL",
                  "Prior_YTD": "342102",
                  "GP_Sales1": "42.4%",
                  "+/-%": "100",
                  "K_YTD": "111",
                  "K_GP_Sales": "0.0%",
                  "K_Prior_YTD": "720831",
                  "K_GP_Sales1": "38.7%",
                  "K_+/-%": "-100",
                  "KA_YTD": "111",
                  "KA_GP_Sale": "0.0%",
                  "KA_Prior_YTD": "720831",
                  "KA_GP_Sale1": "37.8%",
                  "KA_+/-%": "-100",
                  "N_YTD": "111",
                  "N_GP_Sale": "0.4%",
                  "N_Prior_YTD": "720831",
                  "N_GP_Sale1": "0.4%",
                  "N_+/-%": "-100",
                  // "IS_USER_INPUT": false
                }
              ]
            }
          ]

        }
      ]
    }
  ]
}

export const mockforkeyInsightTdaag = [
  {
    "reportHeaderData": [
      {
        "COMPANY_CODE": "14",
        "COMPANY_NAME": "Toyota",
        "COMPANY": "14 - Toyota",
        "REGION_CODE": "120",
        "REGION": "120 - San Francisco",
        "REGION_NAME": "San Francisco",
        "DISTRICT_CODE": "120D",
        "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
        "DEALER_CODE": "4143",
        "DEALER_NAME": "City Toyota",
        "DEALER": "04143 - City Toyota",
        "NATIONAL": "NATIONAL"
      }
    ],
    "reportData": [
      {
        "SECTIONID": 1,
        "SECTIONTITLE": "Sales",
        "sectionData": [
          {

            "IS_USER_INPUT": false,
            "SECTIONID": 1,
            "SECTIONTITLE": "Sales",
            "SECTIONLINENO": 1,
            "ACCT_NO": "B9659",
            "ACCT_NO_GP": null,
            "acct_name": "P&A Cust Mech Sls",
            "DLR_CY": "6205260.0000",
            "DLR_GP_CY": null,
            "DLR_PY": "4342484.0000",
            "DLR_GP_PY": null,
            "DEALER_DIFF": "42.89",
            "DIST_CY": "3218154",
            "DIST_GP_CY": null,
            "DIST_PY": "2786534",
            "DIST_GP_PY": null,
            "DISTRICT_DIFF": "15.48",
            "RGN_CY": "2901781",
            "RGN_GP_CY": null,
            "RGN_PY": "2591865",
            "RGN_GP_PY": null,
            "REGION_DIFF": "11.95",
            "NTNL_CY": "2192125",
            "NTNL_GP_CY": null,
            "NTNL_PY": "2007870",
            "NTNL_GP_PY": null,
            "NATIONAL_DIFF": "9.17"
          },
          {

            "IS_USER_INPUT": false,
            "SECTIONID": 1,
            "SECTIONTITLE": "Sales",
            "SECTIONLINENO": 2,
            "ACCT_NO": "4710S",
            "ACCT_NO_GP": null,
            "acct_name": "P&A Body Shop Sls",
            "DLR_CY": "0.0000",
            "DLR_GP_CY": null,
            "DLR_PY": "0.0000",
            "DLR_GP_PY": null,
            "DEALER_DIFF": "0.00",
            "DIST_CY": "152028",
            "DIST_GP_CY": null,
            "DIST_PY": "135914",
            "DIST_GP_PY": null,
            "DISTRICT_DIFF": "11.85",
            "RGN_CY": "131857",
            "RGN_GP_CY": null,
            "RGN_PY": "114463",
            "RGN_GP_PY": null,
            "REGION_DIFF": "15.19",
            "NTNL_CY": "364408",
            "NTNL_GP_CY": null,
            "NTNL_PY": "336516",
            "NTNL_GP_PY": null,
            "NATIONAL_DIFF": "8.28"
          }
        ]
      },
      {
        "SECTIONID": "2",
        "SECTIONTITLE": "Gross Profit $/%",
        "sectionData": [
          {
            "IS_USER_INPUT": false,
            "SECTIONID": 2,
            "SECTIONTITLE": "Gross Profit $/%",
            "SECTIONLINENO": 1,
            "ACCT_NO": "B9660",
            "ACCT_NO_GP": "B8046",
            "acct_name": "Total  P & A Mechanical/TC/TC+ GP",
            "DLR_CY": "1877303.0000",
            "DLR_GP_CY": "38.0",
            "DLR_PY": "1316164.0000",
            "DLR_GP_PY": "38.1",
            "DEALER_DIFF": "42.63",
            "DIST_CY": "1108915",
            "DIST_GP_CY": "42.2",
            "DIST_PY": "959660",
            "DIST_GP_PY": "42.0",
            "DISTRICT_DIFF": "15.55",
            "RGN_CY": "987690",
            "RGN_GP_CY": "41.5",
            "RGN_PY": "871637",
            "RGN_GP_PY": "40.8",
            "REGION_DIFF": "13.31",
            "NTNL_CY": "679932",
            "NTNL_GP_CY": "0.4",
            "NTNL_PY": "622945",
            "NTNL_GP_PY": "0.4",
            "NATIONAL_DIFF": "9.14"
          },
          {
            "IS_USER_INPUT": false,
            "SECTIONID": 2,
            "SECTIONTITLE": "Gross Profit $/%",
            "SECTIONLINENO": 2,
            "ACCT_NO": "4710T",
            "ACCT_NO_GP": "B8049",
            "acct_name": "Body Shop P & A  GP",
            "DLR_CY": "0.0000",
            "DLR_GP_CY": "0.0",
            "DLR_PY": "0.0000",
            "DLR_GP_PY": "0.0",
            "DEALER_DIFF": "0.00",
            "DIST_CY": "54653",
            "DIST_GP_CY": "35.9",
            "DIST_PY": "49471",
            "DIST_GP_PY": "36.4",
            "DISTRICT_DIFF": "10.47",
            "RGN_CY": "44929",
            "RGN_GP_CY": "34.1",
            "RGN_PY": "39523",
            "RGN_GP_PY": "34.5",
            "REGION_DIFF": "13.67",
            "NTNL_CY": "111221",
            "NTNL_GP_CY": "0.3",
            "NTNL_PY": "103238",
            "NTNL_GP_PY": "0.3",
            "NATIONAL_DIFF": "7.73"
          },
          {
            "sectionNo": 3,
            "sectionTitle": "Inventory",
            "sectionData": [
              {
                "Line": "1",
                "Gross_profit": "P & A Inventory",
                "YTD": "111",
                "GP_Sales": "2.2%",
                "Prior_YTD": "342102",
                "GP_Sales1": "42.4%",
                "+/-%": "-100",
                "K_YTD": "111",
                "K_GP_Sales": "0.0%",
                "K_Prior_YTD": "720831",
                "K_GP_Sales1": "38.7%",
                "K_+/-%": "-100",
                "KA_YTD": "111",
                "KA_GP_Sale": "0.0%",
                "KA_Prior_YTD": "720831",
                "KA_GP_Sale1": "37.8%",
                "KA_+/-%": "-100",
                "N_YTD": "111",
                "N_GP_Sale": "0.4%",
                "N_Prior_YTD": "720831",
                "N_GP_Sale1": "0.4%",
                "N_+/-%": "-100",
                // "IS_USER_INPUT": false
              },
              {
                "Line": "2",
                "Gross_profit": "Inv.- P and A - Other",
                "YTD": "111",
                "GP_Sales": "NULL",
                "Prior_YTD": "342102",
                "GP_Sales1": "42.4%",
                "+/-%": "100",
                "K_YTD": "111",
                "K_GP_Sales": "0.0%",
                "K_Prior_YTD": "720831",
                "K_GP_Sales1": "38.7%",
                "K_+/-%": "-100",
                "KA_YTD": "111",
                "KA_GP_Sale": "0.0%",
                "KA_Prior_YTD": "720831",
                "KA_GP_Sale1": "37.8%",
                "KA_+/-%": "-100",
                "N_YTD": "111",
                "N_GP_Sale": "0.4%",
                "N_Prior_YTD": "720831",
                "N_GP_Sale1": "0.4%",
                "N_+/-%": "-100",
                // "IS_USER_INPUT": false
              }
            ]
          }
        ]

      }
    ]
  }
]

export const TdaagSlmMockData = {
 body:[ 
  {
      "reportData": [
          {
              "SECTIONID": "1",
              "SECTIONTITLE": "TLE Efficiency(TLEe) 12 MONTH ROLLING",
              "sectionData": [
                  {
                      "SECTIONTITLE": "TLE Efficiency(TLEe) 12 MONTH ROLLING",
                      "ROWTYPE": "District - Metro or Single Point",
                      "COL1": "0.00",
                      "COL2": "0.00",
                      "COL3": "84.36",
                      "COL4": "83.67",
                      "COL5": "82.42",
                      "X_SEQ": 3,
                      "SECTIONID": 1
                  },
                  {
                      "SECTIONTITLE": "TLE Efficiency(TLEe) 12 MONTH ROLLING",
                      "ROWTYPE": "Region/PD - Metro or Single Point",
                      "COL1": "0.00",
                      "COL2": "0.00",
                      "COL3": "83.40",
                      "COL4": "83.06",
                      "COL5": "82.24",
                      "X_SEQ": 4,
                      "SECTIONID": 1
                  },
                  {
                      "SECTIONTITLE": "TLE Efficiency(TLEe) 12 MONTH ROLLING",
                      "ROWTYPE": "Dealer - Metro or Single Point",
                      "COL1": "0.00",
                      "COL2": "0.00",
                      "COL3": "81.14",
                      "COL4": "81.62",
                      "COL5": "79.71",
                      "X_SEQ": 1,
                      "SECTIONID": 1
                  }
              ]
          },
          {
              "SECTIONID": "2",
              "SECTIONTITLE": "TLE 12 MONTH ROLLING",
              "sectionData": [
                  {
                      "SECTIONTITLE": "TLE 12 MONTH ROLLING",
                      "ROWTYPE": "Dealer - Metro or Single Point",
                      "COL1": "0.00",
                      "COL2": "0.00",
                      "COL3": "97.29",
                      "COL4": "98.27",
                      "COL5": "96.92",
                      "X_SEQ": 1,
                      "SECTIONID": 2
                  },
                  {
                      "SECTIONTITLE": "TLE 12 MONTH ROLLING",
                      "ROWTYPE": "TLEe Bottom 10% in Region/PD - All Dealers",
                      "COL1": null,
                      "COL2": null,
                      "COL3": "N",
                      "COL4": "N",
                      "COL5": "N",
                      "X_SEQ": 6,
                      "SECTIONID": 2
                  },
                  {
                      "SECTIONTITLE": "TLE 12 MONTH ROLLING",
                      "ROWTYPE": "TLEe Rank in Region/PD - All Dealers",
                      "COL1": null,
                      "COL2": null,
                      "COL3": "137/177",
                      "COL4": "125/177",
                      "COL5": "141/177",
                      "X_SEQ": 5,
                      "SECTIONID": 2
                  }
              ]
          }
      ],
      "reportHeaderData": [
        [
            {
                "TITLE": "10017 - Butler Toyota",
                "PYEAR": 2022,
                "CYEAR": 2023
            }
        ],
        [
            {
                "LEVELID": 10,
                "LEVEL_NAME": "dealer"
            },
            {
                "LEVELID": 11,
                "LEVEL_NAME": "district"
            },
            {
                "LEVELID": 12,
                "LEVEL_NAME": "region"
            }
        ]
    ]
  }
  ]  
}


export const ServiceDeptAnalysisMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "4143",
          "DEALER_NAME": "City Toyota",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL",
          'TITLE': "TLE EFFICEINCY 12 MONTH ROLLING",
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Sales",
          "sectionData": [
            {

              "IS_USER_INPUT": false,
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NO": "B9659",
              "ACCT_NO_GP": null,
              "acct_name": "P&A Cust Mech Sls",
              "DLR_CY": "6205260.0000",
              "DLR_GP_CY": null,
              "DLR_PY": "4342484.0000",
              "DLR_GP_PY": null,
              "DEALER_DIFF": "42.89",
              "DIST_CY": "3218154",
              "DIST_GP_CY": null,
              "DIST_PY": "2786534",
              "DIST_GP_PY": null,
              "DISTRICT_DIFF": "15.48",
              "RGN_CY": "2901781",
              "RGN_GP_CY": null,
              "RGN_PY": "2591865",
              "RGN_GP_PY": null,
              "REGION_DIFF": "11.95",
              "NTNL_CY": "2192125",
              "NTNL_GP_CY": null,
              "NTNL_PY": "2007870",
              "NTNL_GP_PY": null,
              "NATIONAL_DIFF": "9.17",
              "GUIDES": "<20%"
            },
            {

              "IS_USER_INPUT": false,
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 2,
              "ACCT_NO": "4710S",
              "ACCT_NO_GP": null,
              "acct_name": "P&A Body Shop Sls",
              "DLR_CY": "0.0000",
              "DLR_GP_CY": null,
              "DLR_PY": "0.0000",
              "DLR_GP_PY": null,
              "DEALER_DIFF": "0.00",
              "DIST_CY": "152028",
              "DIST_GP_CY": null,
              "DIST_PY": "135914",
              "DIST_GP_PY": null,
              "DISTRICT_DIFF": "11.85",
              "RGN_CY": "131857",
              "RGN_GP_CY": null,
              "RGN_PY": "114463",
              "RGN_GP_PY": null,
              "REGION_DIFF": "15.19",
              "NTNL_CY": "364408",
              "NTNL_GP_CY": null,
              "NTNL_PY": "336516",
              "NTNL_GP_PY": null,
              "NATIONAL_DIFF": "8.28",
              "GUIDES": "<20%"
            }
          ]
        },
        {
          "SECTIONID": "2",
          "SECTIONTITLE": "Gross Profit $/%",
          "sectionData": [
            {
              "IS_USER_INPUT": false,
              "SECTIONID": 2,
              "SECTIONTITLE": "Gross Profit $/%",
              "SECTIONLINENO": 1,
              "ACCT_NO": "B9660",
              "ACCT_NO_GP": "B8046",
              "acct_name": "Total  P & A Mechanical/TC/TC+ GP",
              "DLR_CY": "1877303.0000",
              "DLR_GP_CY": "38.0",
              "DLR_PY": "1316164.0000",
              "DLR_GP_PY": "38.1",
              "DEALER_DIFF": "42.63",
              "DIST_CY": "1108915",
              "DIST_GP_CY": "42.2",
              "DIST_PY": "959660",
              "DIST_GP_PY": "42.0",
              "DISTRICT_DIFF": "15.55",
              "RGN_CY": "987690",
              "RGN_GP_CY": "41.5",
              "RGN_PY": "871637",
              "RGN_GP_PY": "40.8",
              "REGION_DIFF": "13.31",
              "NTNL_CY": "679932",
              "NTNL_GP_CY": "0.4",
              "NTNL_PY": "622945",
              "NTNL_GP_PY": "0.4",
              "NATIONAL_DIFF": "9.14",
              "GUIDES": "<20%"
            },
            {
              "IS_USER_INPUT": false,
              "SECTIONID": 2,
              "SECTIONTITLE": "Gross Profit $/%",
              "SECTIONLINENO": 2,
              "ACCT_NO": "4710T",
              "ACCT_NO_GP": "B8049",
              "acct_name": "Body Shop P & A  GP",
              "DLR_CY": "0.0000",
              "DLR_GP_CY": "0.0",
              "DLR_PY": "0.0000",
              "DLR_GP_PY": "0.0",
              "DEALER_DIFF": "0.00",
              "DIST_CY": "54653",
              "DIST_GP_CY": "35.9",
              "DIST_PY": "49471",
              "DIST_GP_PY": "36.4",
              "DISTRICT_DIFF": "10.47",
              "RGN_CY": "44929",
              "RGN_GP_CY": "34.1",
              "RGN_PY": "39523",
              "RGN_GP_PY": "34.5",
              "REGION_DIFF": "13.67",
              "NTNL_CY": "111221",
              "NTNL_GP_CY": "0.3",
              "NTNL_PY": "103238",
              "NTNL_GP_PY": "0.3",
              "NATIONAL_DIFF": "7.73",
              "GUIDES": "<20%"
            },
            {
              "sectionNo": 3,
              "sectionTitle": "Inventory",
              "sectionData": [
                {
                  "Line": "1",
                  "Gross_profit": "P & A Inventory",
                  "YTD": "111",
                  "GP_Sales": "2.2%",
                  "Prior_YTD": "342102",
                  "GP_Sales1": "42.4%",
                  "+/-%": "-100",
                  "K_YTD": "111",
                  "K_GP_Sales": "0.0%",
                  "K_Prior_YTD": "720831",
                  "K_GP_Sales1": "38.7%",
                  "K_+/-%": "-100",
                  "KA_YTD": "111",
                  "KA_GP_Sale": "0.0%",
                  "KA_Prior_YTD": "720831",
                  "KA_GP_Sale1": "37.8%",
                  "KA_+/-%": "-100",
                  "N_YTD": "111",
                  "N_GP_Sale": "0.4%",
                  "N_Prior_YTD": "720831",
                  "N_GP_Sale1": "0.4%",
                  "N_+/-%": "-100",
                  "GUIDES": "<20%"
                },
                {
                  "Line": "2",
                  "Gross_profit": "Inv.- P and A - Other",
                  "YTD": "111",
                  "GP_Sales": "NULL",
                  "Prior_YTD": "342102",
                  "GP_Sales1": "42.4%",
                  "+/-%": "100",
                  "K_YTD": "111",
                  "K_GP_Sales": "0.0%",
                  "K_Prior_YTD": "720831",
                  "K_GP_Sales1": "38.7%",
                  "K_+/-%": "-100",
                  "KA_YTD": "111",
                  "KA_GP_Sale": "0.0%",
                  "KA_Prior_YTD": "720831",
                  "KA_GP_Sale1": "37.8%",
                  "KA_+/-%": "-100",
                  "N_YTD": "111",
                  "N_GP_Sale": "0.4%",
                  "N_Prior_YTD": "720831",
                  "N_GP_Sale1": "0.4%",
                  "N_+/-%": "-100",
                  "GUIDES": "<20%"
                }
              ]
            }
          ]

        }
      ]
    }
  ]
}

export const SalesAndPartsAnalysisMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "04285",
          "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Sales",
          "sectionData": [
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Customer Pay Labor Sales",
              "CLASS_AVG": "34",
              "SELECTED_MONTH": "29",
              "YTD": "265",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Customer Pay Labor Sales",
              "CLASS_AVG": "65",
              "SELECTED_MONTH": "71",
              "YTD": "456",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Customer Pay Labor Sales",
              "CLASS_AVG": "98",
              "SELECTED_MONTH": "100",
              "YTD": "722",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            }
          ]
        },
        {
          "SECTIONID": 2,
          "SECTIONTITLE": "PROFITABILITY",
          "sectionData": [
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "PROFITABILITY",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Cust Mech Labor GP",
              "CLASS_AVG": "34",
              "SELECTED_MONTH": "60",
              "YTD": "306",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "PROFITABILITY",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Cust Mech Labor GP",
              "CLASS_AVG": "1",
              "SELECTED_MONTH": "0",
              "YTD": "0",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "PROFITABILITY",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Cust Mech Labor GP",
              "CLASS_AVG": "(18.5%)",
              "SELECTED_MONTH": "(51.7%)",
              "YTD": "(13.4%)",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            }
          ]
        },
      ]
    }
  ]
}


export const TCUVPerformanceSummaryMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "04285",
          "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Measure",
          "sectionData": [
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Total Dealership GP % Sales",
              "MEASURE_EMPTY": "NULL",
              "DEALERSHIP_MTD": "11.73",
              "DEALERSHIP_MTD_EMPTY": "NULL",
              "DEALERSHIP_YTD": "12.90",
              "GUIDE": "11% to 13%",
              "FONTCOLOR": 'green'

            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Used Department GP % Sales",
              "MEASURE_EMPTY": "NULL",
              "DEALERSHIP_MTD": "7.27",
              "DEALERSHIP_MTD_EMPTY": "NULL",
              "DEALERSHIP_YTD": "7.83",
              "GUIDE": "10% to 13%",
              "FONTCOLOR": 'red'
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 3,
              "ACCT_NAME": "Used GP Contribution",
              "MEASURE_EMPTY": "NULL",
              "DEALERSHIP_MTD": "19.95",
              "DEALERSHIP_YTD": "19.26",
              "DEALERSHIP_MTD_EMPTY": "NULL",
              "GUIDE": "10% to 15%",
              "FONTCOLOR": 'red'
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 4,
              "ACCT_NAME": "Used Vehicle Equity",
              "MEASURE_EMPTY": "NULL",
              "DEALERSHIP_MTD": "100.00",
              "DEALERSHIP_YTD": "100.00",
              "DEALERSHIP_MTD_EMPTY": "NULL",
              "GUIDE": ">50%",
              "FONTCOLOR": 'green'
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 5,
              "ACCT_NAME": "Floor Plan Expense",
              "MEASURE_EMPTY": "NULL",
              "DEALERSHIP_MTD": "11.68",
              "DEALERSHIP_YTD": "10.73",
              "GUIDE": "1% to 2%",
              "DEALERSHIP_MTD_EMPTY": "NULL",
              "FONTCOLOR": 'red'
            }

          ]
        },
        {
          "SECTIONID": 2,
          "SECTIONTITLE": "Key Metric",
          "sectionData": [
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "Key Metric",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "TCUV GP PUVR/L",
              "MEASURE_EMPTY": "NULL",
              "DEALER_TCUV": "1,682",
              "DISTRICT": "811",
              "REGION_TCUV": "1,068",
              "NATIONAL": "1,265",
              "FONTCOLOR": 'green'

            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "Key Metric",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Other Toyota GP PUVR/L",
              "MEASURE_EMPTY": "NULL",
              "DEALER_TCUV": "1,682",
              "DISTRICT": "259",
              "REGION_TCUV": "843",
              "NATIONAL": "1,267",
              "FONTCOLOR": 'green'
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "Key Metric",
              "SECTIONLINENO": 3,
              "ACCT_NAME": "Other Used GP PUVR/L",
              "MEASURE_EMPTY": "NULL",
              "DEALER_TCUV": "1,833",
              "DISTRICT": "792",
              "REGION_TCUV": "743",
              "NATIONAL": "1,184",
              "FONTCOLOR": 'red'
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "Key Metric",
              "SECTIONLINENO": 4,
              "ACCT_NAME": "Total Used Vehicle GP PUVR/L",
              "MEASURE_EMPTY": "NULL",
              "DEALER_TCUV": "1,334",
              "DISTRICT": "603",
              "REGION_TCUV": "906",
              "NATIONAL": "1,237",
              "FONTCOLOR": 'green'
            },
          ]
        },
        {
          "SECTIONID": 3,
          "SECTIONTITLE": "Monthly Store Average",
          "sectionData": [
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "Monthly Store Average",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "TCUV GP PUVR/L",
              "DEALER_TCUV": "1,682",
              "DISTRICT": "811",
              "REGION_TCUV": "1,068",
              "NATIONAL": "1,265"
            },
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "Monthly Store Average",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Other Toyota GP PUVR/L",
              "DEALER_TCUV": "1,682",
              "DISTRICT": "259",
              "REGION_TCUV": "843",
              "NATIONAL": "1,267"
            },
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "Monthly Store Average",
              "SECTIONLINENO": 3,
              "ACCT_NAME": "Other Used GP PUVR/L",
              "DEALER_TCUV": "1,833",
              "DISTRICT": "792",
              "REGION_TCUV": "743",
              "NATIONAL": "1,184"
            },
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "Key Metric",
              "SECTIONLINENO": 4,
              "ACCT_NAME": "Total Used Vehicle GP PUVR/L",
              "DEALER_TCUV": "1,334",
              "DISTRICT": "603",
              "REGION_TCUV": "906",
              "NATIONAL": "1,237"
            },
          ]
        },
      ]
    }
  ],
  status: 200
}

export const TCUVPerformanceDashboardMockData = {
  body: {
    "operations": [
      {
        "reportData": [
          {
            "SECTIONNO": "1",
            "SECTIONTITLE": "Total Dealership Gross Profit % of Sales",
            "sectionData": [
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Total Dealership Gross Profit % of Sales",
                "OPERTAIONS_TYPE": "MTD",
                "DLR_GROSS_PROFIT": "928,000",
                "/": "NULL",
                "DLR_SALES": "5,756,820",
                "=": "NULL",
                "GP_SALES": "16.12",
                "GUIDE": "11% to 13%"

              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Total Dealership Gross Profit % of Sales",
                "OPERTAIONS_TYPE": "YTD",
                "DLR_GROSS_PROFIT": "928,000",
                "/": "NULL",
                "DLR_SALES": "5,756,820",
                "=": "NULL",
                "GP_SALES": "16.12",
                "GUIDE": "11% to 13%"

              }
            ]
          },
          {
            "SECTIONNO": "2",
            "SECTIONTITLE": "Total Dealership Net Profit % of Sales",
            "sectionData": [
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Total Dealership Net Profit % of Sales",
                "OPERTAIONS_TYPE": "MTD",
                "DLR_GROSS_PROFIT": "928,000",
                "/": "NULL",
                "DLR_SALES": "5,756,820",
                "=": "NULL",
                "GP_SALES": "16.12",
                "GUIDE": "11% to 13%"

              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Total Dealership Net Profit % of Sales",
                "OPERTAIONS_TYPE": "YTD",
                "DLR_GROSS_PROFIT": "928,000",
                "/": "NULL",
                "DLR_SALES": "5,756,820",
                "=": "NULL",
                "GP_SALES": "16.12",
                "GUIDE": "11% to 13%"

              }
            ]
          }
        ],
        "reportHeaderData": [
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "120",
            "REGION": "120 - San Francisco",
            "REGION_NAME": "San Francisco",
            "DISTRICT_CODE": "120D",
            "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
            "DEALER_CODE": "04285",
            "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
            "DEALER": "04143 - City Toyota",
            "NATIONAL": "NATIONAL",
            "OPERTAIONS_TYPE": "",
            "DLR_GROSS_PROFIT": "Total Dealership Gross Profit $ 6999T Pg. 6 Ln 66",
            "/": "/",
            "DLR_SALES": "Total Dealership Sales $ 6998T Pg. 6 Ln 66	",
            "=": "=",
            "GP_SALES": "GP % of Sales",
            "GUIDE": "Guide"
          }
        ],
      }
    ],
    "usedvehicleinventory": [
      {
        "reportData": [
          {
            "SECTIONNO": "1",
            "SECTIONTITLE": "Total Dealership Gross Profit % of Sales",
            "sectionData": [
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Total Dealership Gross Profit % of Sales",
                "OPERTAIONS_TYPE": "MTD",
                "DLR_GROSS_PROFIT": "928,000",
                "/": "NULL",
                "DLR_SALES": "5,756,820",
                "=": "NULL",
                "GP_SALES": "16.12",
                "GUIDE": "11% to 13%"

              },
              {
                "SECTIONID": 1,
                "SECTIONTITLE": "Total Dealership Gross Profit % of Sales",
                "OPERTAIONS_TYPE": "YTD",
                "DLR_GROSS_PROFIT": "928,000",
                "/": "NULL",
                "DLR_SALES": "5,756,820",
                "=": "NULL",
                "GP_SALES": "16.12",
                "GUIDE": "11% to 13%"

              }
            ]
          },
          {
            "SECTIONNO": "2",
            "SECTIONTITLE": "Total Dealership Net Profit % of Sales",
            "sectionData": [
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Total Dealership Net Profit % of Sales",
                "OPERTAIONS_TYPE": "MTD",
                "DLR_GROSS_PROFIT": "928,000",
                "/": "NULL",
                "DLR_SALES": "5,756,820",
                "=": "NULL",
                "GP_SALES": "16.12",
                "GUIDE": "11% to 13%"

              },
              {
                "SECTIONID": 2,
                "SECTIONTITLE": "Total Dealership Net Profit % of Sales",
                "OPERTAIONS_TYPE": "YTD",
                "DLR_GROSS_PROFIT": "928,000",
                "/": "NULL",
                "DLR_SALES": "5,756,820",
                "=": "NULL",
                "GP_SALES": "16.12",
                "GUIDE": "11% to 13%"

              }
            ]
          }
        ],
        "reportHeaderData": [
          {
            "COMPANY_CODE": "14",
            "COMPANY_NAME": "Toyota",
            "COMPANY": "14 - Toyota",
            "REGION_CODE": "120",
            "REGION": "120 - San Francisco",
            "REGION_NAME": "San Francisco",
            "DISTRICT_CODE": "120D",
            "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
            "DEALER_CODE": "04285",
            "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
            "DEALER": "04143 - City Toyota",
            "NATIONAL": "NATIONAL",
            "OPERTAIONS_TYPE": "",
            "DLR_GROSS_PROFIT": "Total Dealership Gross Profit $ 6999T Pg. 6 Ln 66",
            "/": "/",
            "DLR_SALES": "Total Dealership Sales $ 6998T Pg. 6 Ln 66	",
            "=": "=",
            "GP_SALES": "GP % of Sales",
            "GUIDE": "Guide"
          }
        ],
      }
    ]
  },
  status: 200
}

export const TCUVPerformanceCalendarYearTurnsMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "04285",
          "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Measure",
          "sectionData": [
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 1,
              "STEP": "NULL",
              "ACCT_NAME": "YTD Retail TCUV Sales - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "NULL",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 345

            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 2,
              "STEP": "NULL",
              "ACCT_NAME": "YTD Retail Used Toyota Sales - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "+",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 345
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 3,
              "STEP": "Step 1",
              "ACCT_NAME": "	YTD Retail Other Used Sales - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "+",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 345
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 4,
              "STEP": "",
              "ACCT_NAME": "YTD Wholesale Used Sales - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "+",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 345
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 5,
              "STEP": "",
              "ACCT_NAME": "YTD Total Used Vehicle Sales - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "+",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 342
            },

            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 6,
              "STEP": "NULL",
              "ACCT_NAME": "	YTD Retail TCUV Gross - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "NULL",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 345

            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 7,
              "STEP": "NULL",
              "ACCT_NAME": "YTD Retail Used Toyota Gross - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "+",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 345
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 8,
              "STEP": "Step 2",
              "ACCT_NAME": "	YTD Retail Other Used Gross - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "+",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 345
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 9,
              "STEP": "",
              "ACCT_NAME": "YTD Wholesale Used Gross - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "+",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 345
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Measure",
              "SECTIONLINENO": 10,
              "STEP": "",
              "ACCT_NAME": "YTD Total Used Vehicle Gross - $",
              "FINANCE_INFO": "	4321T+4331T Page 6 Ln 1, 2",
              "OPERATOR": "+",
              "TOTALS": "17,478,526	",
              "GUIDE": "NULL",
              "FORMATTYPE": 342
            }


          ]
        }

      ]
    }
  ],
  status: 200
}

export const mockTableHeader = [
  {
    "Parts": '2',
    "MTD(AVERAGE DEALER)": '1',
    "DEALER 6-MONTH HISTORY": '6'
  }
]



export const SalesAnalysisNewVehicleMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "04285",
          "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Sales",
          "sectionData": [
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Retail Total Sales",
              "CLASS AVG": "34",
              "SELECTED MONTH": "29",
              "YTD": "265",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Total New Trk Toy Retail/Lease",
              "CLASS AVG": "65",
              "SELECTED MONTH": "71",
              "YTD": "456",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "New Toyota Vehicle Units",
              "CLASS AVG": "98",
              "SELECTED MONTH": "100",
              "YTD": "722",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            }
          ]
        },
        {
          "SECTIONID": 2,
          "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
          "sectionData": [
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Retail Total Sales",
              "CLASS AVG": "34",
              "SELECTED MONTH": "60",
              "YTD": "306",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Fleet - Total Sales",
              "CLASS AVG": "1",
              "SELECTED MONTH": "0",
              "YTD": "0",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Toyota Car Sales YTD% Change",
              "CLASS AVG": "(18.5%)",
              "SELECTED MONTH": "(51.7%)",
              "YTD": "(13.4%)",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            }
          ]
        },
      ]
    }
  ]
}

export const PsFinancialMockData = {
  "PSFinancial":
  {
    "tableData": [
      {
        " ": "P & A Customer Mechanical Sales",
        "2024 ": "$3,388,930	",
        "2023 ": "$2,804,773 ",
        "Change ": "	",
        "2024  ": "$25,664,093",
        "2023   ": "$27,712,525",
        "CHANGE   ": " ",
      },
      {
        " ": "Total P & A MRO + Exp Lube",
        "2024 ": "$3,388,930	",
        "2023 ": "$2,804,773 ",
        "Change ": "	",
        "2024  ": "$25,664,093",
        "2023   ": "$27,712,525",
        "CHANGE   ": " ",
      },
      {
        " ": "Total  P & A Mechanical/TC/TC+ GP",
        "2024 ": "$3,388,930	",
        "2023 ": "$2,804,773 ",
        "Change ": "	",
        "2024  ": "$25,664,093",
        "2023   ": "$27,712,525",
        "CHANGE   ": " ",
      },
      {
        " ": "P & A Customer Mechanical GP % of Sales",
        "2024 ": "$3,388,930	",
        "2023 ": "$2,804,773 ",
        "Change ": "	",
        "2024  ": "$25,664,093",
        "2023   ": "$27,712,525",
        "CHANGE   ": " ",
      },
      {
        " ": "P & A Mechanical RO Tickets",
        "2024 ": "$3,388,930	",
        "2023 ": "$2,804,773 ",
        "Change ": "	",
        "2024  ": "$25,664,093",
        "2023   ": "$27,712,525",
        "CHANGE   ": " ",
      },
      {
        " ": "Mechanical Sales % of Total",
        "2024 ": "$3,388,930	",
        "2023 ": "$2,804,773 ",
        "Change ": "	",
        "2024  ": "$25,664,093",
        "2023   ": "$27,712,525",
        "CHANGE   ": " ",
      },

    ],
    "tableHeader": [
      {
        "CURRENT MONTH": '4',
        "YEAR TO DATE": '3',
      }
    ]
  }

}

export const SalesAnalysisUsedVehicleMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "04285",
          "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Sales",
          "sectionData": [
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "TTL Toyota/Trac Used Veh R/L Aft Recon Units",
              "CLASS AVG": "34",
              "SELECTED MONTH": "29",
              "YTD": "265",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            }
          ]
        },
        {
          "SECTIONID": 2,
          "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
          "sectionData": [
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "TTL Toyota/Trac Used Veh R/L Aft Recon Units",
              "CLASS AVG": "34",
              "SELECTED MONTH": "60",
              "YTD": "306",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "%Change-Tot UsedRetail & Lease",
              "CLASS AVG": "1",
              "SELECTED MONTH": "0",
              "YTD": "0",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            }
          ]
        },
        {
          "SECTIONID": 3,
          "SECTIONTITLE": "ASSET MANAGEMENT",
          "sectionData": [
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "ASSET MANAGEMENT",
              "SECTIONLINENO": 3,
              "ACCT_NAME": "Other Used Veh Inventory-$",
              "CLASS AVG": "34",
              "SELECTED MONTH": "60",
              "YTD": "306",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "ASSET MANAGEMENT",
              "SECTIONLINENO": 3,
              "ACCT_NAME": "Other Used Vehicles-Inv",
              "CLASS AVG": "1",
              "SELECTED MONTH": "0",
              "YTD": "0",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "ASSET MANAGEMENT",
              "SECTIONLINENO": 3,
              "ACCT_NAME": "Toyota Car Sales YTD% Change",
              "CLASS AVG": "(18.5%)",
              "SELECTED MONTH": "(51.7%)",
              "YTD": "(13.4%)",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            }
          ]
        }
      ]
    }
  ]
}

export const customSearchObject = [
  { key: 'date', value: '12/01/2023' },
  { key: 'dealer', value: '202202' },
  { key: 'company', value: 'lexus' }
]

export const mockCompanyDealers = [
  { id: 1, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'HOOMAN toyota of long beach' },
  { id: 2, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'Cabe toyota of long beach' },
  { id: 3, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'NORWALK toyota of long beach' },
  { id: 4, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'BEACH CITIES toyota of long beach' },
  { id: 5, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'Toyota of long beach' },
  { id: 6, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'PENSKY toyota of long beach' }

];

export const HrcyMemMock = [
  { dealer_code: 310, dealer_name: 'Western' },
  { dealer_code: 320, dealer_name: 'Central' },
  { dealer_code: 330, dealer_name: 'Estern' },
  { dealer_code: 340, dealer_name: 'Southern' },
]

export const HrcyMock = [
  { name: 'Dealer', id: HIERARCHY_TYPE.DEALER },
  { name: 'District', id: HIERARCHY_TYPE.DISTRICT },
  { name: 'Region', id: HIERARCHY_TYPE.REGION },
  { name: 'National', id: HIERARCHY_TYPE.NATIONAL },
  { name: "Area" , id : HIERARCHY_TYPE.AREA},
  { name: "DSSO" , id : HIERARCHY_TYPE.DSSO},
]



export const CompanyTypeMock = [
  { name: 'Toyota', id: COMPANY_TYPE.TOYOTA },
  { name: 'Lexus', id: COMPANY_TYPE.LEXUS }
]



export const financemock = [
  {
    "1": "TCUV Sales",
    "2000": "37.0",
    "2001": "33.35",
    "% change": "33.35",
    "2002": "37.0",
    "2003": "33.35",
    "DISTRICT": "33.35",
    "REGIONS": "31.691",
    "NATIONAL": "31.691"
  },
  {
    "1": "TCUV TRAC Sls",
    "2000": "37.0",
    "2001": "33.35",
    "% change": "33.35",
    "2002": "37.0",
    "2003": "33.35",
    "DISTRICT": "33.35",
    "REGIONS": "31.691",
    "NATIONAL": "31.691"
  },
  {
    "1": "TCUV TRAC Sls",
    "2000": "37.0",
    "2001": "33.35",
    "% change": "33.35",
    "2002": "37.0",
    "2003": "33.35",
    "DISTRICT": "33.35",
    "REGIONS": "31.691",
    "NATIONAL": "31.691"
  },
  {
    "1": "Total TCUV Sales",
    "2000": "37.0",
    "2001": "33.35",
    "% change": "33.35",
    "2002": "37.0",
    "2003": "33.35",
    "DISTRICT": "33.35",
    "REGIONS": "31.691",
    "NATIONAL": "31.691"
  },
  {
    "1": "Toyota Used",
    "2000": "37.0",
    "2001": "33.35",
    "% change": "33.35",
    "2002": "37.0",
    "2003": "33.35",
    "DISTRICT": "33.35",
    "REGIONS": "31.691",
    "NATIONAL": "31.691"
  },
  {
    "1": "Non Toyota Used",
    "2000": "37.0",
    "2001": "33.35",
    "% change": "33.35",
    "2002": "37.0",
    "2003": "33.35",
    "DISTRICT": "33.35",
    "REGIONS": "31.691",
    "NATIONAL": "31.691"
  },
  {
    "1": "Total Used Sales",
    "2000": "37.0",
    "2001": "33.35",
    "% change": "33.35",
    "2002": "37.0",
    "2003": "33.35",
    "DISTRICT": "33.35",
    "REGIONS": "31.691",
    "NATIONAL": "31.691"
  },
  {
    "1": "TCUV to Ttl Used",
    "2000": "37.0",
    "2001": "33.35",
    "% change": "33.35",
    "2002": "37.0",
    "2003": "33.35",
    "DISTRICT": "33.35",
    "REGIONS": "31.691",
    "NATIONAL": "31.691"
  }
]

export const DealerReviewReportMockData = {
  body: [
    {
      "reportData": [
        [
          {
            "SECTIONID": "10",
            "SECTIONTITLE": "Year",
            "sectionData": [
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "217",
                "PCAR_SALES_CHANGE": "-24.7%",
                "PCAR_SALES_EFFICIENCY": "165.60",
                "PCAR_MARKET_SHARE": "22.19",
                "PCAR_LEADERSHIP": "TOY",
                "LTRUCK_SALES": "487",
                "LTRUCK_SALES_CHANGE": "-1.8%",
                "LTRUCK_SALES_EFFICIENCY": "257.50",
                "LTRUCK_MARKET_SHARE": "18.45",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "704",
                "TOTAL_NEW_SALES_CHANGE": "-10.2%",
                "TOTAL_INSDUSTRY_REGIS": "3,866",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "35.7%",
                "TOTAL_TOYOTA_REGIS": "767",
                "TOTAL_TOYOTA_REGIS_CHANGE": "41.8%",
                "TOTAL_USED_SALES": "146",
                "TOTAL_USED_SALES_CHANGE": "21.7%"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "236",
                "PCAR_SALES_CHANGE": "8.8%",
                "PCAR_SALES_EFFICIENCY": "142.80",
                "PCAR_MARKET_SHARE": "21.92",
                "PCAR_LEADERSHIP": "HON",
                "LTRUCK_SALES": "492",
                "LTRUCK_SALES_CHANGE": "1.0%",
                "LTRUCK_SALES_EFFICIENCY": "211.50",
                "LTRUCK_MARKET_SHARE": "16.66",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "728",
                "TOTAL_NEW_SALES_CHANGE": "3.4%",
                "TOTAL_INSDUSTRY_REGIS": "4,372",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "13.1%",
                "TOTAL_TOYOTA_REGIS": "872",
                "TOTAL_TOYOTA_REGIS_CHANGE": "13.7%",
                "TOTAL_USED_SALES": "143",
                "TOTAL_USED_SALES_CHANGE": "-2.1%"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "223",
                "PCAR_SALES_CHANGE": "-5.5%",
                "PCAR_SALES_EFFICIENCY": "134.70",
                "PCAR_MARKET_SHARE": "22.29",
                "PCAR_LEADERSHIP": "HON",
                "LTRUCK_SALES": "554",
                "LTRUCK_SALES_CHANGE": "12.6%",
                "LTRUCK_SALES_EFFICIENCY": "152.90",
                "LTRUCK_MARKET_SHARE": "18.15",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "777",
                "TOTAL_NEW_SALES_CHANGE": "6.7%",
                "TOTAL_INSDUSTRY_REGIS": "4,474",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "2.3%",
                "TOTAL_TOYOTA_REGIS": "876",
                "TOTAL_TOYOTA_REGIS_CHANGE": "0.5%",
                "TOTAL_USED_SALES": "106",
                "TOTAL_USED_SALES_CHANGE": "-25.9%"
              }
            ]
          },
          {
            "SECTIONID": "12",
            "SECTIONTITLE": "Region 110",
            "sectionData": [
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "730",
                "PCAR_SALES_CHANGE": "-23.6%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "21.53",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,061",
                "LTRUCK_SALES_CHANGE": "-13.3%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "15.27",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,792",
                "TOTAL_NEW_SALES_CHANGE": "-17.8%",
                "TOTAL_INSDUSTRY_REGIS": "11623",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "29.9%",
                "TOTAL_TOYOTA_REGIS": "2332",
                "TOTAL_TOYOTA_REGIS_CHANGE": "39.7%",
                "TOTAL_USED_SALES": "415",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "747",
                "PCAR_SALES_CHANGE": "2.3%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "19.99",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,099",
                "LTRUCK_SALES_CHANGE": "3.6%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "13.93",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,846",
                "TOTAL_NEW_SALES_CHANGE": "3.1%",
                "TOTAL_INSDUSTRY_REGIS": "13,020",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "12.0%",
                "TOTAL_TOYOTA_REGIS": "2510",
                "TOTAL_TOYOTA_REGIS_CHANGE": "7.6%",
                "TOTAL_USED_SALES": "406",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "734",
                "PCAR_SALES_CHANGE": "-1.8%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "21.38",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,186",
                "LTRUCK_SALES_CHANGE": "7.9%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "14.22",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,920",
                "TOTAL_NEW_SALES_CHANGE": "4.0%",
                "TOTAL_INSDUSTRY_REGIS": "13,333",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "2.4%",
                "TOTAL_TOYOTA_REGIS": "2567",
                "TOTAL_TOYOTA_REGIS_CHANGE": "2.3%",
                "TOTAL_USED_SALES": "393",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              }
            ]
          }
        ],
        [
          {
            "SECTIONID": "10",
            "SECTIONTITLE": "Year",
            "sectionData": [
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              }
            ]
          },
          {
            "SECTIONID": "12",
            "SECTIONTITLE": "Region 110",
            "sectionData": [
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              }
            ]
          },
        ],
        [
         
              {
                "EXP": "NULL",
                "TDA": "24 Months",
                "PROVISIONS": "Facility Non-Waiver - Submit detailed written facility action plan",
                "DEADLINES": "NULL",
                "DEADLINES_EMPTY": "NULL",
                "PHASE": "Image USA II- Compliant",
                "DATE_LAST_MEASURED": "NULL"
              },
              {
                "EXP": "NULL",
                "TDA": "NULL",
                "PROVISIONS": "Compliance with Capitalization and Financial Requirements - Non-Waiver",
                "DEADLINES": "NULL",
                "DEADLINES_EMPTY": "NULL",
                "PHASE": "NULL",
                "DATE_LAST_MEASURED": "NULL"
              },
              {
                "EXP": "NULL",
                "TDA": "NULL",
                "PROVISIONS": "Achieve 90% Sales Efficiency",
                "DEADLINES": "NULL",
                "DEADLINES_EMPTY": "NULL",
                "PHASE": "NULL",
                "DATE_LAST_MEASURED": "NULL"
              }
          
        ]
      ],
      "reportHeaderData": [
        {
          "REGION_CODE": "110"
        }
      ]
    },
    {
      "reportData": [
        [
          {
            "SECTIONID": "10",
            "SECTIONTITLE": "Year",
            "sectionData": [
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "217",
                "PCAR_SALES_CHANGE": "-24.7%",
                "PCAR_SALES_EFFICIENCY": "165.60",
                "PCAR_MARKET_SHARE": "22.19",
                "PCAR_LEADERSHIP": "TOY",
                "LTRUCK_SALES": "487",
                "LTRUCK_SALES_CHANGE": "-1.8%",
                "LTRUCK_SALES_EFFICIENCY": "257.50",
                "LTRUCK_MARKET_SHARE": "18.45",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "704",
                "TOTAL_NEW_SALES_CHANGE": "-10.2%",
                "TOTAL_INSDUSTRY_REGIS": "3,866",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "35.7%",
                "TOTAL_TOYOTA_REGIS": "767",
                "TOTAL_TOYOTA_REGIS_CHANGE": "41.8%",
                "TOTAL_USED_SALES": "146",
                "TOTAL_USED_SALES_CHANGE": "21.7%"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "236",
                "PCAR_SALES_CHANGE": "8.8%",
                "PCAR_SALES_EFFICIENCY": "142.80",
                "PCAR_MARKET_SHARE": "21.92",
                "PCAR_LEADERSHIP": "HON",
                "LTRUCK_SALES": "492",
                "LTRUCK_SALES_CHANGE": "1.0%",
                "LTRUCK_SALES_EFFICIENCY": "211.50",
                "LTRUCK_MARKET_SHARE": "16.66",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "728",
                "TOTAL_NEW_SALES_CHANGE": "3.4%",
                "TOTAL_INSDUSTRY_REGIS": "4,372",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "13.1%",
                "TOTAL_TOYOTA_REGIS": "872",
                "TOTAL_TOYOTA_REGIS_CHANGE": "13.7%",
                "TOTAL_USED_SALES": "143",
                "TOTAL_USED_SALES_CHANGE": "-2.1%"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "223",
                "PCAR_SALES_CHANGE": "-5.5%",
                "PCAR_SALES_EFFICIENCY": "134.70",
                "PCAR_MARKET_SHARE": "22.29",
                "PCAR_LEADERSHIP": "HON",
                "LTRUCK_SALES": "554",
                "LTRUCK_SALES_CHANGE": "12.6%",
                "LTRUCK_SALES_EFFICIENCY": "152.90",
                "LTRUCK_MARKET_SHARE": "18.15",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "777",
                "TOTAL_NEW_SALES_CHANGE": "6.7%",
                "TOTAL_INSDUSTRY_REGIS": "4,474",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "2.3%",
                "TOTAL_TOYOTA_REGIS": "876",
                "TOTAL_TOYOTA_REGIS_CHANGE": "0.5%",
                "TOTAL_USED_SALES": "106",
                "TOTAL_USED_SALES_CHANGE": "-25.9%"
              }
            ]
          },
          {
            "SECTIONID": "12",
            "SECTIONTITLE": "Region 110",
            "sectionData": [
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "730",
                "PCAR_SALES_CHANGE": "-23.6%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "21.53",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,061",
                "LTRUCK_SALES_CHANGE": "-13.3%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "15.27",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,792",
                "TOTAL_NEW_SALES_CHANGE": "-17.8%",
                "TOTAL_INSDUSTRY_REGIS": "11623",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "29.9%",
                "TOTAL_TOYOTA_REGIS": "2332",
                "TOTAL_TOYOTA_REGIS_CHANGE": "39.7%",
                "TOTAL_USED_SALES": "415",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "747",
                "PCAR_SALES_CHANGE": "2.3%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "19.99",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,099",
                "LTRUCK_SALES_CHANGE": "3.6%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "13.93",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,846",
                "TOTAL_NEW_SALES_CHANGE": "3.1%",
                "TOTAL_INSDUSTRY_REGIS": "13,020",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "12.0%",
                "TOTAL_TOYOTA_REGIS": "2510",
                "TOTAL_TOYOTA_REGIS_CHANGE": "7.6%",
                "TOTAL_USED_SALES": "406",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "734",
                "PCAR_SALES_CHANGE": "-1.8%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "21.38",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,186",
                "LTRUCK_SALES_CHANGE": "7.9%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "14.22",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,920",
                "TOTAL_NEW_SALES_CHANGE": "4.0%",
                "TOTAL_INSDUSTRY_REGIS": "13,333",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "2.4%",
                "TOTAL_TOYOTA_REGIS": "2567",
                "TOTAL_TOYOTA_REGIS_CHANGE": "2.3%",
                "TOTAL_USED_SALES": "393",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              }
            ]
          }
        ],
        [
          {
            "SECTIONID": "10",
            "SECTIONTITLE": "Year",
            "sectionData": [
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              }
            ]
          },
          {
            "SECTIONID": "12",
            "SECTIONTITLE": "Region 110",
            "sectionData": [
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              }
            ]
          },
        ],
        [
         
              {
                "EXP": "NULL",
                "TDA": "24 Months",
                "PROVISIONS": "Facility Non-Waiver - Submit detailed written facility action plan",
                "DEADLINES": "NULL",
                "DEADLINES_EMPTY": "NULL",
                "PHASE": "Image USA II- Compliant",
                "DATE_LAST_MEASURED": "NULL"
              },
              {
                "EXP": "NULL",
                "TDA": "NULL",
                "PROVISIONS": "Compliance with Capitalization and Financial Requirements - Non-Waiver",
                "DEADLINES": "NULL",
                "DEADLINES_EMPTY": "NULL",
                "PHASE": "NULL",
                "DATE_LAST_MEASURED": "NULL"
              },
              {
                "EXP": "NULL",
                "TDA": "NULL",
                "PROVISIONS": "Achieve 90% Sales Efficiency",
                "DEADLINES": "NULL",
                "DEADLINES_EMPTY": "NULL",
                "PHASE": "NULL",
                "DATE_LAST_MEASURED": "NULL"
              }
          
        ]
      ],
      "reportHeaderData": [
        {
          "REGION_CODE": "110"
        }
      ]
    },
    {
      "reportData": [
        [
          {
            "SECTIONID": "10",
            "SECTIONTITLE": "Year",
            "sectionData": [
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "217",
                "PCAR_SALES_CHANGE": "-24.7%",
                "PCAR_SALES_EFFICIENCY": "165.60",
                "PCAR_MARKET_SHARE": "22.19",
                "PCAR_LEADERSHIP": "TOY",
                "LTRUCK_SALES": "487",
                "LTRUCK_SALES_CHANGE": "-1.8%",
                "LTRUCK_SALES_EFFICIENCY": "257.50",
                "LTRUCK_MARKET_SHARE": "18.45",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "704",
                "TOTAL_NEW_SALES_CHANGE": "-10.2%",
                "TOTAL_INSDUSTRY_REGIS": "3,866",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "35.7%",
                "TOTAL_TOYOTA_REGIS": "767",
                "TOTAL_TOYOTA_REGIS_CHANGE": "41.8%",
                "TOTAL_USED_SALES": "146",
                "TOTAL_USED_SALES_CHANGE": "21.7%"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "236",
                "PCAR_SALES_CHANGE": "8.8%",
                "PCAR_SALES_EFFICIENCY": "142.80",
                "PCAR_MARKET_SHARE": "21.92",
                "PCAR_LEADERSHIP": "HON",
                "LTRUCK_SALES": "492",
                "LTRUCK_SALES_CHANGE": "1.0%",
                "LTRUCK_SALES_EFFICIENCY": "211.50",
                "LTRUCK_MARKET_SHARE": "16.66",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "728",
                "TOTAL_NEW_SALES_CHANGE": "3.4%",
                "TOTAL_INSDUSTRY_REGIS": "4,372",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "13.1%",
                "TOTAL_TOYOTA_REGIS": "872",
                "TOTAL_TOYOTA_REGIS_CHANGE": "13.7%",
                "TOTAL_USED_SALES": "143",
                "TOTAL_USED_SALES_CHANGE": "-2.1%"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "223",
                "PCAR_SALES_CHANGE": "-5.5%",
                "PCAR_SALES_EFFICIENCY": "134.70",
                "PCAR_MARKET_SHARE": "22.29",
                "PCAR_LEADERSHIP": "HON",
                "LTRUCK_SALES": "554",
                "LTRUCK_SALES_CHANGE": "12.6%",
                "LTRUCK_SALES_EFFICIENCY": "152.90",
                "LTRUCK_MARKET_SHARE": "18.15",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "777",
                "TOTAL_NEW_SALES_CHANGE": "6.7%",
                "TOTAL_INSDUSTRY_REGIS": "4,474",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "2.3%",
                "TOTAL_TOYOTA_REGIS": "876",
                "TOTAL_TOYOTA_REGIS_CHANGE": "0.5%",
                "TOTAL_USED_SALES": "106",
                "TOTAL_USED_SALES_CHANGE": "-25.9%"
              }
            ]
          },
          {
            "SECTIONID": "12",
            "SECTIONTITLE": "Region 110",
            "sectionData": [
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "730",
                "PCAR_SALES_CHANGE": "-23.6%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "21.53",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,061",
                "LTRUCK_SALES_CHANGE": "-13.3%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "15.27",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,792",
                "TOTAL_NEW_SALES_CHANGE": "-17.8%",
                "TOTAL_INSDUSTRY_REGIS": "11623",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "29.9%",
                "TOTAL_TOYOTA_REGIS": "2332",
                "TOTAL_TOYOTA_REGIS_CHANGE": "39.7%",
                "TOTAL_USED_SALES": "415",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "747",
                "PCAR_SALES_CHANGE": "2.3%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "19.99",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,099",
                "LTRUCK_SALES_CHANGE": "3.6%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "13.93",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,846",
                "TOTAL_NEW_SALES_CHANGE": "3.1%",
                "TOTAL_INSDUSTRY_REGIS": "13,020",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "12.0%",
                "TOTAL_TOYOTA_REGIS": "2510",
                "TOTAL_TOYOTA_REGIS_CHANGE": "7.6%",
                "TOTAL_USED_SALES": "406",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "734",
                "PCAR_SALES_CHANGE": "-1.8%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "21.38",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,186",
                "LTRUCK_SALES_CHANGE": "7.9%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "14.22",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,920",
                "TOTAL_NEW_SALES_CHANGE": "4.0%",
                "TOTAL_INSDUSTRY_REGIS": "13,333",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "2.4%",
                "TOTAL_TOYOTA_REGIS": "2567",
                "TOTAL_TOYOTA_REGIS_CHANGE": "2.3%",
                "TOTAL_USED_SALES": "393",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              }
            ]
          }
        ],
        [
          {
            "SECTIONID": "10",
            "SECTIONTITLE": "Year",
            "sectionData": [
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              }
            ]
          },
          {
            "SECTIONID": "12",
            "SECTIONTITLE": "Region 110",
            "sectionData": [
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              }
            ]
          },
        ],
        [
         
              {
                "EXP": "NULL",
                "TDA": "24 Months",
                "PROVISIONS": "Facility Non-Waiver - Submit detailed written facility action plan",
                "DEADLINES": "NULL",
                "DEADLINES_EMPTY": "NULL",
                "PHASE": "Image USA II- Compliant",
                "DATE_LAST_MEASURED": "NULL"
              },
              {
                "EXP": "NULL",
                "TDA": "NULL",
                "PROVISIONS": "Compliance with Capitalization and Financial Requirements - Non-Waiver",
                "DEADLINES": "NULL",
                "DEADLINES_EMPTY": "NULL",
                "PHASE": "NULL",
                "DATE_LAST_MEASURED": "NULL"
              },
              {
                "EXP": "NULL",
                "TDA": "NULL",
                "PROVISIONS": "Achieve 90% Sales Efficiency",
                "DEADLINES": "NULL",
                "DEADLINES_EMPTY": "NULL",
                "PHASE": "NULL",
                "DATE_LAST_MEASURED": "NULL"
              }
          
        ]
      ],
      "reportHeaderData": [
        {
          "REGION_CODE": "110"
        }
      ]
    },
    {
      "reportData": [
        [
          {
            "SECTIONID": "10",
            "SECTIONTITLE": "Year",
            "sectionData": [
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "217",
                "PCAR_SALES_CHANGE": "-24.7%",
                "PCAR_SALES_EFFICIENCY": "165.60",
                "PCAR_MARKET_SHARE": "22.19",
                "PCAR_LEADERSHIP": "TOY",
                "LTRUCK_SALES": "487",
                "LTRUCK_SALES_CHANGE": "-1.8%",
                "LTRUCK_SALES_EFFICIENCY": "257.50",
                "LTRUCK_MARKET_SHARE": "18.45",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "704",
                "TOTAL_NEW_SALES_CHANGE": "-10.2%",
                "TOTAL_INSDUSTRY_REGIS": "3,866",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "35.7%",
                "TOTAL_TOYOTA_REGIS": "767",
                "TOTAL_TOYOTA_REGIS_CHANGE": "41.8%",
                "TOTAL_USED_SALES": "146",
                "TOTAL_USED_SALES_CHANGE": "21.7%"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "236",
                "PCAR_SALES_CHANGE": "8.8%",
                "PCAR_SALES_EFFICIENCY": "142.80",
                "PCAR_MARKET_SHARE": "21.92",
                "PCAR_LEADERSHIP": "HON",
                "LTRUCK_SALES": "492",
                "LTRUCK_SALES_CHANGE": "1.0%",
                "LTRUCK_SALES_EFFICIENCY": "211.50",
                "LTRUCK_MARKET_SHARE": "16.66",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "728",
                "TOTAL_NEW_SALES_CHANGE": "3.4%",
                "TOTAL_INSDUSTRY_REGIS": "4,372",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "13.1%",
                "TOTAL_TOYOTA_REGIS": "872",
                "TOTAL_TOYOTA_REGIS_CHANGE": "13.7%",
                "TOTAL_USED_SALES": "143",
                "TOTAL_USED_SALES_CHANGE": "-2.1%"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "223",
                "PCAR_SALES_CHANGE": "-5.5%",
                "PCAR_SALES_EFFICIENCY": "134.70",
                "PCAR_MARKET_SHARE": "22.29",
                "PCAR_LEADERSHIP": "HON",
                "LTRUCK_SALES": "554",
                "LTRUCK_SALES_CHANGE": "12.6%",
                "LTRUCK_SALES_EFFICIENCY": "152.90",
                "LTRUCK_MARKET_SHARE": "18.15",
                "LTRUCK_LEADERSHIP": "TOY",
                "TOTAL_NEW_SALES": "777",
                "TOTAL_NEW_SALES_CHANGE": "6.7%",
                "TOTAL_INSDUSTRY_REGIS": "4,474",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "2.3%",
                "TOTAL_TOYOTA_REGIS": "876",
                "TOTAL_TOYOTA_REGIS_CHANGE": "0.5%",
                "TOTAL_USED_SALES": "106",
                "TOTAL_USED_SALES_CHANGE": "-25.9%"
              }
            ]
          },
          {
            "SECTIONID": "12",
            "SECTIONTITLE": "Region 110",
            "sectionData": [
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "730",
                "PCAR_SALES_CHANGE": "-23.6%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "21.53",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,061",
                "LTRUCK_SALES_CHANGE": "-13.3%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "15.27",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,792",
                "TOTAL_NEW_SALES_CHANGE": "-17.8%",
                "TOTAL_INSDUSTRY_REGIS": "11623",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "29.9%",
                "TOTAL_TOYOTA_REGIS": "2332",
                "TOTAL_TOYOTA_REGIS_CHANGE": "39.7%",
                "TOTAL_USED_SALES": "415",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "747",
                "PCAR_SALES_CHANGE": "2.3%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "19.99",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,099",
                "LTRUCK_SALES_CHANGE": "3.6%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "13.93",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,846",
                "TOTAL_NEW_SALES_CHANGE": "3.1%",
                "TOTAL_INSDUSTRY_REGIS": "13,020",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "12.0%",
                "TOTAL_TOYOTA_REGIS": "2510",
                "TOTAL_TOYOTA_REGIS_CHANGE": "7.6%",
                "TOTAL_USED_SALES": "406",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "PCAR_SALES": "734",
                "PCAR_SALES_CHANGE": "-1.8%",
                "PCAR_SALES_EFFICIENCY": "NULL",
                "PCAR_MARKET_SHARE": "21.38",
                "PCAR_LEADERSHIP": "NULL",
                "LTRUCK_SALES": "1,186",
                "LTRUCK_SALES_CHANGE": "7.9%",
                "LTRUCK_SALES_EFFICIENCY": "NULL",
                "LTRUCK_MARKET_SHARE": "14.22",
                "LTRUCK_LEADERSHIP": "NULL",
                "TOTAL_NEW_SALES": "1,920",
                "TOTAL_NEW_SALES_CHANGE": "4.0%",
                "TOTAL_INSDUSTRY_REGIS": "13,333",
                "TOTAL_INSDUSTRY_REGIS_CHANGE": "2.4%",
                "TOTAL_TOYOTA_REGIS": "2567",
                "TOTAL_TOYOTA_REGIS_CHANGE": "2.3%",
                "TOTAL_USED_SALES": "393",
                "TOTAL_USED_SALES_CHANGE": "NULL"
              }
            ]
          }
        ],
        [
          {
            "SECTIONID": "10",
            "SECTIONTITLE": "Year",
            "sectionData": [
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "10",
                "SECTIONTITLE": "Year",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              }
            ]
          },
          {
            "SECTIONID": "12",
            "SECTIONTITLE": "Region 110",
            "sectionData": [
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              },
              {
                "SECTIONID": "12",
                "SECTIONTITLE": "Region 110",
                "YEAR_TAG": "2ndP",
                "CUST_PAY_LABOR": "2,824,589",
                "CUST_PAY_LABOR_CHANGE": "26.8%",
                "CUST_PAY_RO": "17,133",
                "CUST_PAY_RO_CHANGE": "1.0%",
                "RO_MECH_PARTS": "1,445,719",
                "RO_MECH_PARTS_CHANGE": "10.9%",
                "RETAIL_PARTS": "613,306",
                "RETAIL_PARTS_CHANGE": "14.6%",
                "WHOLESALES_PARTS": "3,463,349",
                "WHOLESALES_PARTS_CHANGE": "13.9%",
                "TOTAL_SOLD": "NULL",
                "ONE_VISIT_SOLD": "NULL",
                "TWO_VISIT_SOLD": "NULL",
                "SSI": "NULL",
                "VDQ": "NULL",
                "SPSI": "NULL",
                "PERCENT_SALES": "5.03",
                "BEFORE_TAXES": "2,578,990"
              }
            ]
          },
        ],
        [
         
              {
                "EXP": "NULL",
                "TDA": "24 Months",
                "PROVISIONS": "Facility Non-Waiver - Submit detailed written facility action plan",
                "DEADLINES": "NULL",
                "DEADLINES_EMPTY": "NULL",
                "PHASE": "Image USA II- Compliant",
                "DATE_LAST_MEASURED": "NULL"
              },
              {
                "EXP": "NULL",
                "TDA": "NULL",
                "PROVISIONS": "Compliance with Capitalization and Financial Requirements - Non-Waiver",
                "DEADLINES": "NULL",
                "DEADLINES_EMPTY": "NULL",
                "PHASE": "NULL",
                "DATE_LAST_MEASURED": "NULL"
              },
              {
                "EXP": "NULL",
                "TDA": "NULL",
                "PROVISIONS": "Achieve 90% Sales Efficiency",
                "DEADLINES": "NULL",
                "DEADLINES_EMPTY": "NULL",
                "PHASE": "NULL",
                "DATE_LAST_MEASURED": "NULL"
              }
          
        ]
      ],
      "reportHeaderData": [
        {
          "REGION_CODE": "110"
        }
      ]
    },
  ]
}

