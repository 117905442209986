import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { combineLatest, forkJoin, Observable, Subscription } from "rxjs";
import { COMPANY_TYPE, REPORT_MSGS, reportUrlType } from "src/app/constants/constants";
import { SpinnerService } from "src/app/services/spinner.service";
import { ToastService } from 'src/app/services/toast.service';
import { HIERARCHY_TYPE, reportId } from 'src/app/constants/constants';
import { CustomReportsApiService } from "../../services/api/custom-report-api.service";
import { CustomReportsFiltersService } from "../../services/custom-report-filters.service";
import { CustomReportsService } from "../../services/custom-report.service";
import { getImageFromBase64, getLogosForDealers, getMonthAndYear, getObjFromFilteredArray, getReportTitleToDisplay } from "../../utils/common-utils";
import * as _ from 'lodash';
import { IMAGE_CONTENT } from "src/app/constants/imageContent";

@Component({
  selector: 'app-dealer-performance-new-used-combined-fs-fin',
  templateUrl: './dealer-performance-new-used-combined-fs-fin.component.html',
  styleUrls: ['./dealer-performance-new-used-combined-fs-fin.component.scss']
})
export class DealerPerformaceNewUsedCombinedFsFinComponent implements OnInit {
  public dealerPerformaceNewUsedCombinedFsFinjsonObj;
  public subscription: Subscription = new Subscription();
  public sources: Observable<any>[] = [];
  public reportId: number;
  public reportTitleToDisplay: string;
  public noDataToDisplayMsg: string;
  selectedFilteredValues: any = [];
  public selectedFilter = [];
  public obj = Object.values;
  public companyCode: number;
  public imgPath: string;
  public hierarchy: number;
  public dealerId: number;
  public dealerName: number;
  public rightLogoPath: string;
  public reportTitle: string = '';
  public isDealerHierarchy: boolean = false;

  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private customerReportService: CustomReportsService,
    public toast: ToastService,
    public route: Router
  ) {
    const reportTypeNameFromUrl = this.route.url.split('/').pop();
    this.reportId = reportUrlType[reportTypeNameFromUrl];
    const reportList = JSON.parse(localStorage.getItem('report-name'))
    this.reportTitle = reportList?.[this.reportId];

    this.getDealerPerformanceNewUsedCombinedFsFinData();
  }
  ngOnInit(): void {
    this.subscribeReportData();
  }

  public removeAllFilters(): void {
    this.filterService?.setRemovedValues({});
  }


  public getTabDataSource() {
    const obj = getObjFromFilteredArray(this.selectedFilter);
    this.companyCode = obj['company'].id;
    this.hierarchy = obj['hierarchy'].id;
    this.dealerId = obj['dealer'].MEMBER_CODE;
    this.dealerName = obj['dealer'].MEMBER_NAME;
    const dateObj = getMonthAndYear(obj['monthYear']);
    const view_Id = this.getViewId(obj['company']);

    const requestObj = {
      month: dateObj.searchMonth,
      year: dateObj.searchYear,
      view_id: view_Id,
      hierarchy: obj['hierarchy'].id,
      hierarchy_member_code: obj['dealer'].MEMBER_CODE,
    }
    this.reportTitleToDisplay = getReportTitleToDisplay(obj);

    this.sources = [this.customReportsApiService.getDealerPerformaceNewUsedCombinedFsFinData(requestObj).pipe(this.customerReportService.handleError()?.bind(this))
    ]

    return this.sources;
  }

  public subscribeReportData() {
    this.subscription.add(
      this.filterService.getSelectedValues().subscribe({
        next: (selectedFilter) => {
          if (selectedFilter?.length > 0) {
            this.selectedFilteredValues = selectedFilter;
            this.spinnerService.displaySpinner();
            this.selectedFilter = selectedFilter;
            this.mapResponse();
          }
        }, error: (error) => {
          console.error('Error handler:', error)
          this.spinnerService.hideSpinner();
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.removeAllFilters();
    this.filterService.setSelectedValues([]);
    this.customerReportService.setPdfExcelIcons(false);
    this.subscription.unsubscribe();
  }

  public getDealerPerformanceNewUsedCombinedFsFinData() {
    this.dealerPerformaceNewUsedCombinedFsFinjsonObj = {
      multiDataSources: true,
      datasource: []

    }
  }

  public async mapResponse() {
    let currentTableResponse = [];
    let array = [];
    await this.getTabDataSource();

    this.subscription.add(forkJoin(this.sources).subscribe({
      next: (resp) => {
        if (resp.length > 0 && resp[0].status === 200) {
          this.customerReportService.setPdfExcelIcons(true);
          this.imgPath = getImageFromBase64(this.companyCode);
          if(this.hierarchy === HIERARCHY_TYPE.DEALER){
          this.rightLogoPath = getLogosForDealers(this.companyCode);
          }
          if (this.companyCode === COMPANY_TYPE.TOYOTA) {
            array = ['new', 'certifiedUsed', 'nonCertifiedUsed', 'combined'];
          } else {
            array = ['new', 'certifiedUsed', 'combined'];
          }

          array.forEach(element => {
            let reportData = resp[0].body?.[element]
            if (reportData?.length > 0) {
              currentTableResponse = [...currentTableResponse, ...this.assignReportData(reportData, element.toUpperCase())];
            } else {
              this.noDataToDisplayMsg = REPORT_MSGS.NO_RECORDS_FOUND;
            }

          });
        }
        this.dealerPerformaceNewUsedCombinedFsFinjsonObj.datasource = currentTableResponse;
        this.spinnerService.hideSpinner();
      }, error: (err) => {
        console.error('Error handler: mapping response', err);
        this.spinnerService.hideSpinner();
      }
    })
    )
  }

  public assignReportData(reportData, mainHeader) {
    if (mainHeader === 'CERTIFIEDUSED') {
      mainHeader = "CERTIFIED USED";
    } else if (mainHeader === 'NONCERTIFIEDUSED') {
      mainHeader = "NON-CERTIFIED USED";
    }
    let colSpanValue = 12;
    let partDepReportHeader = { NationalDprUedNewCombined: [{ [mainHeader]: colSpanValue }] };
    let dataArr = [];
    reportData.forEach((ele, index) => {
      if (ele.sectionData?.length > 0) {
        const obj = {
          'datasourceName': `nationalDprUsedNewCombined ${index}`,
          'tableData': ele.sectionData,
          'sectionTitle': ele.SECTIONTITLE,
          'sectionId': ele.SECTIONNO,
          'removeLineBreak': true,
          'showLineNumbers': true,
          'showIndicator': this.hierarchy === HIERARCHY_TYPE.DEALER ? true : false,
          'dataFormattingColumns': 'all'

        };
        if (index === 0) {
          obj['tableHeader'] = partDepReportHeader.NationalDprUedNewCombined;
          obj["tableHeaderClass"] = 'dxfw-national-dpr-header'
        }
        dataArr.push(obj)
      }
    });
    return dataArr;
  }


  public getViewId(company) {
    const companyName = company?.name.toLowerCase();
    return `dpr_new_used_comb_tfs_fin_${companyName}`;
  }

}  